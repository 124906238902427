import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Tab,
  Nav,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../../Classes/Util";
import { Variables } from "../../../Variables1/Variables";
import { Link } from "react-router-dom";
import CustomSnipper from "../../../components/customSnipper";

function Orders(props) {

    
  const [orderStatus, SetOrderStatus] = useState([]);
 const [branchesOrder,setBranchesOrder] = useState([]);
  const [orderDetailsModal, setOrderDetailsModal] = useState(0);
  const [search, setSearch] = useState("");
  const [orderToUpdate, setOrderToUpdate] = useState({
    OrderId: 0,
    statusId: 0,
  });
  const [updateLoader, setUpdateLoader] = useState(false);
  
  const [ordersLoader, setOrdersLoader] = useState(false);


  // Get orders Stautses
  const GetOrdersStatus = async (props) => {
    let response = await fetch(
      Variables.API_URL + "OrderStatus/GetOrderStatus",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      SetOrderStatus(result);
    }
  };

// Get branches orders
const GetBranchesOrders = async () => {
  let response = await fetch(
    Variables.API_URL + "Order/GetBranchesOrderByRestoBranchesBranches/"+props.restaurantId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  let result = await response.json();
  if (response.status === 200) {
    setBranchesOrder(result);
  }
};

  

   // Update Order status
   const UpdateOrderStatus = async (orderId, statusId) => {
    setUpdateLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "Order/UpdateOrderStatus/Order/" +
        orderId +
        "/Status/" +
        statusId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      GetBranchesOrders()
      //props.setRefreshSideBar(true)
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
    setUpdateLoader(false);
  };

  const SetOrderToUpdate = (orderId, e) => {
    const { value } = e.target;
    setOrderToUpdate({
      OrderId: orderId,
      statusId: value,
    });
  };

  const HideModel = () => {
    if (orderToUpdate.OrderId !== 0 && orderToUpdate.statusId !== 0) {
      UpdateOrderStatus(orderToUpdate.OrderId, orderToUpdate.statusId);
      setOrderToUpdate({
        OrderId: 0,
        statusId: 0,
      });
    }
    setOrderDetailsModal(0);
  };

  const OnShowModal = (statusId) => {
    setOrderToUpdate({
      OrderId: 0,
      statusId: statusId,
    });
  }

  // Cancel Update
  const CancelUpdate = () => {
    setOrderToUpdate({
      OrderId: 0,
      statusId: 0,
    });
    setOrderDetailsModal(0);
  }



  useEffect(() => {
    GetOrdersStatus();GetBranchesOrders()
  }, [props.orders,]);

  console.log("resto bra",branchesOrder)

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title"></h4>
              </div>
            </Card.Header>
            <Card.Body className="px-3">
              <div className="bd-example">
                <Tab.Container defaultActiveKey="defaultx">
                  <Nav
                    variant="tabs"
                    className="mb-3"
                    id="nav-tab"
                    role="tablist"
                  >
                    {branchesOrder.map((data, x) => (
                      <Nav key={x + "nav"}>
                        <Nav.Link
                          key={x + "nav-link"}
                          eventKey={
                            x === 0
                              ? "defaultx"
                              : data.Branch.BranchName.replace(" ", "")
                          }
                          variant=""
                          id={
                            "nav-" + data.Branch.BranchName.replace(" ", "") + "-tab"
                          }
                          data-bs-toggle="tab"
                          data-bs-target={
                            "#nav-" + data.Branch.BranchName.replace(" ", "")
                          }
                          type="button"
                          role="tab"
                          aria-controls={
                            "nav-" + data.Branch.BranchName.replace(" ", "")
                          }
                          aria-selected="false"
                        >
                          {data.Branch.BranchName + "  "}{" "}
                          {data.Orders.filter((o) => o.BranchId == data.Branch.BranchId)
                            .length > 0 ? (
                            <span className="badge rounded-pill bg-primary">
                              {
                                data.Orders.filter(
                                  (o) => o.BranchId == data.Branch.BranchId
                                ).length
                              }
                            </span>
                          ) : (
                            " "
                          )}
                        </Nav.Link>
                      </Nav>
                    ))}
                  </Nav>
                  <Tab.Content>
                    {updateLoader === true && <Row>
                      <Col className="allignCenter">
                        <Spinner
                          animation="border"
                          variant="primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>}

                    {branchesOrder.map((data, i) => (
                      <Tab.Pane
                        key={i + "tab-pane"}
                        className=" fade"
                        id={"nav-" + data.Branch.BranchName.replace(" ", "")}
                        eventKey={
                          i === 0
                            ? "defaultx"
                            : data.Branch.BranchName.replace(" ", "")
                        }
                        role="tabpanel"
                        aria-labelledby={
                          "nav-" + data.Branch.BranchName.replace(" ", "") + "-tab"
                        }
                      >
                        {ordersLoader === true ? (
                          <CustomSnipper />
                        ) : (
                          <Tab.Container
                            defaultActiveKey={"default"}
                            key={i + "xx"}
                          >
                            <Nav className="mt-5">
                              {orderStatus.map((status, x) => (
                                <Nav
                                  key={x + "nav1"}
                                  variant="tabs"
                                  className="mb-3"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <Nav.Link
                                    eventKey={
                                      x === 0 ? "default" : status.StatusName
                                    }
                                    variant=" d-flex align-items-center"
                                    id={"nav-" + status.StatusName + "-tab"}
                                    data-bs-toggle="tab"
                                    data-bs-target={"#nav-" + status.StatusName}
                                    type="button"
                                    role="tab"
                                    aria-controls={"nav-" + status.StatusName}
                                    aria-selected="true"
                                  >
                                    {status.StatusName + " "}{" "}
                                    <span className="badge rounded-pill bg-primary">
                                      {
                                        data.Orders.filter(
                                          (o) =>
                                            o.OrderStatusId ===
                                              status.OrderStatusId &&
                                            o.BranchId === data.Branch.BranchId
                                        ).length
                                      }
                                    </span>
                                  </Nav.Link>
                                </Nav>
                              ))}
                            </Nav>
                            <Tab.Content>
                              {orderStatus.map((status, x) => (
                                <Tab.Pane
                                  key={x + "tab-pane1"}
                                  className=" fade show"
                                  eventKey={
                                    x === 0 ? "default" : status.StatusName
                                  }
                                  id={"#nav-" + status.StatusName}
                                  role="tabpanel"
                                  aria-labelledby={
                                    "nav-" + status.StatusName + "-tab"
                                  }
                                >
                                  <table
                                    key={x + "table"}
                                    id="user-list-table"
                                    className="table table-striped"
                                    role="grid"
                                    data-toggle="data-table"
                                  >
                                    <thead>
                                      <tr className="ligth">
                                        <th
                                          onClick={() =>
                                            setOrderDetailsModal(true)
                                          }
                                        >
                                          Order#
                                        </th>
                                        <th>Customer</th>
                                        <th>Payment Method</th>
                                        <th>Order Date</th>
                                        <th>Price</th>
                                        <th min-width="100px">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.Orders
                                        .filter(
                                          (x) =>
                                            x.BranchId === data.Branch.BranchId &&
                                            x.OrderStatusId ===
                                              status.OrderStatusId
                                        )
                                        .map((item, idx) => (
                                          <tr key={idx + "tr"}>
                                            <td className="text-center">
                                              {item.OrderNumber}
                                            </td>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.PaymentMethodName}</td>
                                            <td>{item.Date}</td>
                                            <td>
                                              {item.OrderFinalPriceWithDelivery}
                                            </td>
                                            <td>
                                              <Link
                                                key={idx + "link-td"}
                                                className="btn btn-sm btn-icon btn-success"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title=""
                                                data-original-title="Edit"
                                                to={"#"}
                                                onClick={() =>
                                                  setOrderDetailsModal(
                                                    item.OrderId
                                                  )
                                                }
                                              >
                                                <span className="btn-inner">
                                                  <svg
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                    <path
                                                      d="M15.1655 4.60254L19.7315 9.16854"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </Link>{" "}
                                              <Modal
                                                key={idx}
                                                show={
                                                  orderDetailsModal ===
                                                  item.OrderId
                                                }
                                                size="xl"
                                                onHide={() =>
                                                  setOrderDetailsModal(0)
                                                }
                                                onShow={() => OnShowModal(item.OrderStatusId)}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                animation={true}
                                                autoFocus={true}
                                                keyboard={true}
                                                scrollable={true}
                                                backdrop="static"
                                                onHide={() => GetBranchesOrders()}
                                              >
                                                <Modal.Header>
                                                  <Modal.Title>
                                                    {"Order# : " +
                                                      item.OrderNumber}
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <Row className="mb-5">
                                                    <Col md="3">
                                                      {"Customer : " +
                                                        item.CustomerName}
                                                    </Col>
                                                    <Col md="3">
                                                      {"Payment : " +
                                                        item.PaymentMethodName}
                                                    </Col>
                                                    <Col md="3">
                                                      {"Total Price : " +
                                                        item.OrderFinalPriceWithoutDelivery}
                                                    </Col>
                                                    <Col md="3">
                                                      <select
                                                        className="form-control primary"
                                                        style={{
                                                          backgroundColor:
                                                            "whitesmoke",
                                                        }}
                                                        name="OrderStatusId"
                                                        id={
                                                          "OrderStatusId" + idx
                                                        }
                                                        value={
                                                          orderToUpdate.statusId
                                                        }
                                                        onChange={(e) =>
                                                          SetOrderToUpdate(
                                                            item.OrderId,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        <option
                                                          key={0}
                                                          value={0}
                                                        >
                                                          -- Select Status --
                                                        </option>
                                                        {/* {orderStatus.map(
                                                          (x,xi) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  x.OrderStautusId
                                                                }
                                                                value={
                                                                  x.OrderStatusId
                                                                }
                                                              >
                                                                {(xi+1) + " - " +x.StatusName}
                                                              </option>
                                                            );
                                                          }
                                                        )} */}{item.OrderStatusId === orderStatus[0].OrderStatusId ? orderStatus.map(
                                                          (x,xi) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  x.OrderStautusId
                                                                }
                                                                value={
                                                                  x.OrderStatusId
                                                                }
                                                              >
                                                                {(xi+1) + " - " +x.StatusName}
                                                              </option>
                                                            );
                                                          }
                                                        ) : orderStatus.filter(k => k.OrderStatusId !== orderStatus[0].OrderStatusId).map(
                                                          (x,xi) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  x.OrderStautusId
                                                                }
                                                                value={
                                                                  x.OrderStatusId
                                                                }
                                                              >
                                                                {(xi+1) + " - " +x.StatusName}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    </Col>
                                                  </Row>
                                                  {item.OrderDetails.map(
                                                    (detail, index) => (
                                                      <>
                                                        <Row
                                                          key={index + "row"}
                                                          style={{
                                                            backgroundColor:
                                                              "whitesmoke",
                                                          }}
                                                        >
                                                          <Col md="3">
                                                            <b>
                                                              {"Item : " +
                                                                (index+1) +
                                                                " - " +
                                                                detail
                                                                  .ProductDetails
                                                                  .ProductName}{" "}
                                                              :
                                                            </b>
                                                            <hr className="mt-2" />
                                                            <img
                                                              src={
                                                                Variables.imagePath +
                                                                detail
                                                                  .ProductDetails
                                                                  .ImageUrl
                                                              }
                                                              className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                                                              alt="Boat on Calm Water"
                                                            />
                                                          </Col>
                                                          <Col md="9">
                                                            <Row className="p-3">
                                                              <Col md="6">
                                                                <h6>
                                                                  Quantity :{" "}
                                                                  {
                                                                    detail.Quantity
                                                                  }
                                                                </h6>
                                                              </Col>
                                                              <Col md="6">
                                                                <h6
                                                                  style={{
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                >
                                                                  Total Item
                                                                  Price :{" "}
                                                                  {
                                                                    detail.TotalPriceItem
                                                                  }
                                                                </h6>
                                                              </Col>
                                                            </Row>

                                                            <Row className="p-3 mt-2">
                                                              <Col md="4">
                                                                <h6>
                                                                  Without :
                                                                </h6>
                                                                <hr className="mt-2" />
                                                                <ul>
                                                                  {detail
                                                                    .UnrequiredIngredients
                                                                    .length ===
                                                                  0
                                                                    ? "No Ingredients"
                                                                    : detail.UnrequiredIngredients.map(
                                                                        (
                                                                          unreq,
                                                                          o
                                                                        ) => (
                                                                          <li>
                                                                            {
                                                                              unreq.Name
                                                                            }
                                                                          </li>
                                                                        )
                                                                      )}
                                                                </ul>
                                                              </Col>
                                                              <Col md="4">
                                                                <h6>
                                                                  Addons :
                                                                </h6>
                                                                <hr className="mt-2" />
                                                                <ul>
                                                                  {detail.AddOns
                                                                    .length ===
                                                                  0
                                                                    ? "No AddOns"
                                                                    : detail.AddOns.map(
                                                                        (
                                                                          unreq,
                                                                          o
                                                                        ) => (
                                                                          <li>
                                                                            {
                                                                              unreq.Name
                                                                            }
                                                                          </li>
                                                                        )
                                                                      )}
                                                                </ul>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                        <hr />
                                                      </>
                                                    )
                                                  )}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="secondary"
                                                    onClick={() => CancelUpdate()}
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    onClick={(e) => HideModel()}
                                                  >
                                                    Save changes
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>

                                  {data.Orders
                                        .filter(
                                          (x) =>
                                            x.BranchId === data.Branch.BranchId &&
                                            x.OrderStatusId ===
                                              status.OrderStatusId
                                        ).length === 0 && (
                                    <Row>
                                      <Col
                                        className="Col-Text-Center"
                                        style={{
                                          backgroundColor: "#c2ced93b",
                                          height: "120px",
                                        }}
                                      >
                                        <h6 className="mt-5">
                                          No Orders in {status.StatusName}
                                        </h6>
                                      </Col>
                                    </Row>
                                  )}
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Tab.Container>
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>

              <br></br>
              <br></br>
              <br></br>
              <br></br>

              {/* ////////////////////////////////////////////////////////////////////// */}
              {/* {userBranches.map((data, i) => (
                <div key={i + "p"}>
                  <Row className="" key={i + "x"}>
                    <div className="new-user-info">
                      <Row>
                        <Col className="allignCenter">
                          <h5>
                            Branch : {data.BranchName + " " + data.BranchId}
                          </h5>
                          <hr className="allignCenter" className="mt-2" />
                        </Col>
                      </Row>
                    </div>
                  </Row>
                  <div
                    className="bd-example mb-5"
                    key={i + "y"}
                    style={{ backgroundColor: i % 2 === 0 ? "" : "#c2ced93b" }}
                  >
                    <Tab.Container defaultActiveKey={"default"} key={i + "xx"}>
                      <Nav>
                        {orderStatus.map((status, x) => (
                          <Nav
                            key={x + "z"}
                            variant="tabs"
                            className="mb-3"
                            id="nav-tab"
                            role="tablist"
                          >
                            <Nav.Link
                              eventKey={x === 0 ? "default" : status.StatusName}
                              variant=" d-flex align-items-center"
                              id={"nav-" + status.StatusName + "-tab"}
                              data-bs-toggle="tab"
                              data-bs-target={"#nav-" + status.StatusName}
                              type="button"
                              role="tab"
                              aria-controls={"nav-" + status.StatusName}
                              aria-selected="true"
                            >
                              {status.StatusName}
                            </Nav.Link>
                          </Nav>
                        ))}
                      </Nav>
                      <Tab.Content>
                        {orderStatus.map((status, x) => (
                          <Tab.Pane
                            key={x}
                            className=" fade show"
                            eventKey={x === 0 ? "default" : status.StatusName}
                            id={"#nav-" + status.StatusName}
                            role="tabpanel"
                            aria-labelledby={
                              "nav-" + status.StatusName + "-tab"
                            }
                          >
                            <table
                              id="user-list-table"
                              className="table table-striped"
                              role="grid"
                              data-toggle="data-table"
                            >
                              <thead>
                                <tr className="ligth">
                                  <th>Order#</th>
                                  <th>Customer</th>
                                  <th>Payment Method</th>
                                  <th>Order Date</th>
                                  <th>Price</th>
                                  <th min-width="100px">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders
                                  .filter(
                                    (x) =>
                                      x.BranchId === data.BranchId &&
                                      x.OrderStatusId === status.OrderStatusId
                                  )
                                  .map((item, idx) => (
                                    <tr key={idx + 1}>
                                      <td className="text-center">
                                        {item.OrderNumber}
                                      </td>
                                      <td>{item.CustomerName}</td>
                                      <td>{item.PaymentMethodName}</td>
                                      <td>{item.Date}</td>
                                      <td>
                                        {item.OrderFinalPriceWithDelivery}
                                      </td>
                                      <td>qq</td>
                                    </tr>
                                  ))}
                              </tbody>
                              <tbody></tbody>
                            </table>
                            <Row>
                              <Col className="Col-Text-Center">
                                {orders.filter(
                                  (x) =>
                                    x.BranchId === data.BranchId &&
                                    x.OrderStatusId === status.OrderStatusId
                                ).length === 0 && (
                                  <h6>No Orders in {status.StatusName}</h6>
                                )}
                              </Col>
                            </Row>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              ))} */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default Orders