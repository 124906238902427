import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//router
import { BrowserRouter } from "react-router-dom";
//store

import { Provider } from "react-redux";
//reducer
import Store from "./store";

ReactDOM.render(
  // <React.StrictMode>
  <React.Fragment>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={Store}>
        <App />
      </Provider>
    </BrowserRouter>
  {/* </React.StrictMode>, */}
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
