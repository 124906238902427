import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Variables } from "../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSnipper from "../../components/customSnipper";
import Util from "../../Classes/Util";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
  Nav,
  FormCheck,
  Card,
} from "react-bootstrap";
import BranchDelivery from "./Components/BranchDelivery";
import BranchLocation from "./Components/BranchLocation";
import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";

function ManageBranch() {
  const RouteBranchId = useParams();
  const [branchId, setBranchId] = useState(
    Number(RouteBranchId.RouteBranchId) || 0
  );
  const RestaurantRouteId = useParams();
  const [restaurantId, setRestaurantId] = useState(
    Number(RestaurantRouteId.RestaurantRouteId) || 0
  );
  const navigate = useNavigate();
  const [branchNumbers, setBranchNumbers] = useState([
    {
      NumberId: 0,
      PhoneNumber: "",
    },
  ]);
  const [branchDetails, setBranchDetails] = useState({
    CityId: 0,
    CountryId: 0,
    Map: "",
    Lat: 0,
    Lng: 0,
    DeliveryCharge: 0,
    BranchName: "",
    OpenTime: "00:00",
    CloseTime: "00:00",
    DeliveryStartTime: "00:00",
    DeliveryEndTime: "00:00",
    MinimumOrderPrice: 0
  });
  const [branchIdExist, setBranchIdExist] = useState(true);
  const [detailsLoader, setDetailsLoader] = useState(false);
  const [numbersLoader, setNumbersLoader] = useState(false);
  const [cities, setCities] = useState([
    {
      CityId: 0,
      CityName: "Please Select Country",
    },
  ]);
  const [restaurantCountries, setRestaurantCoutries] = useState([]);
  const [media, setMedia] = useState([]);
  const [mediaLoader, setMediaLoader] = useState(false);

  const [imageModelView, setImageModelView] = useState(0);
  const [deleteImageModel, setDeleteImageMode] = useState(0);

  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const [products, setProducts] = useState([]);
  const [assignedProducts, setAssignedProducts] = useState([]);
  const [assignProductLoader, setAssignProductLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignUsersLoader, setAssignUsersLoader] = useState(false);
  const [usersUpdated, setUsersUpdated] = useState(false);
  const [productsUpdated, setProductUpdated] = useState(false);
  const [mapAttributes, setMapAttributes] = useState([]);

  // Get Branch Details
  const GetBranchDetails = async () => {
    setDetailsLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/GetBranchDetails/Restaurant/" +
        restaurantId +
        "/Branch/" +
        branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setBranchDetails(result);
      GetCitiesByCountryId(result.CountryId);
    } else {
      setBranchIdExist(false);
    }
    setDetailsLoader(false);
  };

  // Get Collections list
  const GetCollections = async () => {
    let response = await fetch(
      Variables.API_URL + "Collection/GetCollections",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setCollections(result);
    }
  };

  // Get Cities By CountryId
  const GetCitiesByCountryId = async (countryId) => {
    let response = await fetch(
      Variables.API_URL + "City/GetCitiesByCountryId/" + countryId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setCities(result);
    }
  };

  // Get RestaurantCountries
  const GetRestaurantCountries = async () => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/GetRestaurantCountries/" + restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRestaurantCoutries(result);
    }
  };

  // handle Branch Details Inputs
  const handleInputs = (e) => {
    const { name, value } = e.target;
    console.log("name : " + name + " value : " + value);

    var element;
    if (value === "") {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setBranchDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle Country Change
  const handleCountryChange = (e) => {
    const { value } = e.target;
    var element;
    if (value === 0 || value === "0") {
      setCities([
        {
          CityId: 0,
          CityName: "Please Select Country",
        },
      ]);
      element = document.getElementById("CountryId");
      element.classList.add("is-invalid");
    } else {
      GetCitiesByCountryId(value);
      element = document.getElementById("CountryId");
      element.classList.remove("is-invalid");
    }
    setBranchDetails((prevState) => ({
      ...prevState,
      ["CountryId"]: value,
    }));
    setBranchDetails((prevState) => ({
      ...prevState,
      ["CityId"]: 0,
    }));
  };

  // handle City Change
  const handleCityChange = (e) => {
    const { value } = e.target;
    var element;
    if (value === 0 || value === "0") {
      element = document.getElementById("CityId");
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById("CityId");
      element.classList.remove("is-invalid");
    }
    setBranchDetails((prevState) => ({
      ...prevState,
      ["CityId"]: value,
    }));
  };

  //Add New Phone
  const AddNewPhone = () => {
    const array = [...branchNumbers];
    const arrayLenght = array.length;
    if (array[arrayLenght - 1].PhoneNumber === "") {
      var element;
      var n = arrayLenght - 1;
      element = document.getElementById("PhoneNumber" + n);
      element.classList.add("is-invalid");
    } else {
      array.push({
        NumberId: 0,
        PhoneNumber: "",
      });
      setBranchNumbers(array);
    }
  };

  // Delete PhoneNumber
  const DeletePhoneNumber = (index) => {
    const array = [...branchNumbers];
    array.splice(index, 1);
    if (array.length === 0) {
      array.push({
        NumberId: 0,
        PhoneNumber: "",
      });
    }
    setBranchNumbers(array);
  };

  // handle Branch Numbers Inputs
  const handleBranchNumbersInputs = (e, i) => {
    const { name, value } = e.target;

    var element;
    if (value === "") {
      element = document.getElementById(name + i);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name + i);
      element.classList.remove("is-invalid");
    }

    let itterationToUpdate = branchNumbers[i];
    const array = [...branchNumbers];
    delete array[i];

    let numberToUpdate = itterationToUpdate.PhoneNumber;
    numberToUpdate = value;

    itterationToUpdate.PhoneNumber = numberToUpdate;

    array[i] = itterationToUpdate;

    setBranchNumbers(array);
  };

  // Get Branch Numbers
  const GetBranchNumbers = async () => {
    setNumbersLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/GetBranchNumbers/Restaurant/" +
        restaurantId +
        "/Branch/" +
        branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setBranchNumbers(result);
    }
    setNumbersLoader(false);
  };

  // Check input validity
  function IsValid() {
    var hasError = false;
    if (branchDetails.CityId === 0 || branchDetails.CityId === "0") {
      var element = document.getElementById("CityId");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    if (branchDetails.CountryId === 0 || branchDetails.CountryId === "0") {
      var element = document.getElementById("CountryId");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    if (branchDetails.BranchName === "" || branchDetails.BranchName === null) {
      var element = document.getElementById("BranchName");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    if(branchDetails.MinimumOrderPrice < 0){
      var element = document.getElementById("MinimumOrderPrice");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    // if (branchDetails.Map === "" || branchDetails.Map === null) {
    //   var element = document.getElementById("Map");
    //   element.classList.add("is-invalid");
    //   hasError = true;
    // }

    const array = [...branchNumbers];
    const arrayLenght = array.length;
    if (array[arrayLenght - 1].PhoneNumber === "") {
      var element;
      var n = arrayLenght - 1;
      element = document.getElementById("PhoneNumber" + n);
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }

    if (assignedProducts.length === 0) {
      var element = document.getElementById("Products").childNodes[0];
      element.classList.add("required");
      element.focus();
      hasError = true;
    }
    if (assignedUsers.length === 0) {
      var element = document.getElementById("Users").childNodes[0];
      element.classList.add("required");
      element.focus();
      hasError = true;
    }

    if (branchDetails.Lat === 0 || branchDetails.Lng === 0) {
      hasError = true;
    }

    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  const GetMedia = async () => {
    setMediaLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranchMedia/GetRestoBranchMedia/Restaurant/" +
        restaurantId +
        "/Branch/" +
        branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setMedia(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setMediaLoader(false);
  };

  const DeleteImage = async (imageId) => {
    setMediaLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranchMedia/DeleteBranchImage/Branch/" +
        branchId +
        "/Image/" +
        imageId,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      GetMedia();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setMediaLoader(false);
  };

  // Handle Drop
  const handleDrop = async (files) => {
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          formData.append("RestaurantId", restaurantId);
          formData.append("BranchId", branchId);
          try {
            const res = await axios.post(
              Variables.API_URL + "UploadMedia/UploadBranchMediaToServer",
              formData
            );
            if (res.data !== "") {
              await fetch(
                Variables.API_URL + "RestoBranchMedia/InsertBranchImageToDb",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    RestoBranchId: branchId,
                    Image: res.data,
                  }),
                }
              ).then((Response) => {
                if (Response.ok) {
                  GetMedia();
                } else {
                }
              });
            }
          } catch (ex) {}
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  // Add new branch
  const AddNewBranch = async () => {
    let response = await fetch(
      Variables.API_URL + "RestoBranch/AddNewRestoBranch",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantId: restaurantId,
          CountryId: branchDetails.CountryId,
          CityId: branchDetails.CityId,
          Map: branchDetails.Map,
          PhoneNumbers: branchNumbers,
          Lat: branchDetails.Lat,
          Lng: branchDetails.Lng,
          DeliveryCharge: branchDetails.DeliveryCharge,
          BranchName: branchDetails.BranchName,
          OpenTime: branchDetails.OpenTime,
          CloseTime: branchDetails.CloseTime,
          DeliveryStartTime: branchDetails.DeliveryStartTime,
    DeliveryEndTime: branchDetails.DeliveryEndTime,
    MinimumOrderPrice: branchDetails.MinimumOrderPrice,
          Collections: selectedCollections,
          Users: assignedUsers,
          Products: assignedProducts,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => navigate("/Restaurant/" + restaurantId), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Update user
  const UpdateBranch = async () => {
    setDetailsLoader(true);
    let response = await fetch(
      Variables.API_URL + "RestoBranch/UpdateRestoBranch",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantId: restaurantId,
          RestoBranchId: branchId,
          RestoBranchDetails: branchDetails,
          RestoBranchNumber: branchNumbers,
          Collections: selectedCollections,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      if (usersUpdated) {
        AssignUsersToBranch();
      }
      if (productsUpdated) {
        AssignProductsToBranch();
      }
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setDetailsLoader(false);
  };

  // Handle Sumbit
  const HandleSumbit = async () => {
    var isValid = IsValid();
    if (isValid) {
      // case of insert
      if (branchId === 0) {
        AddNewBranch();
        setTimeout(() => setDetailsLoader(false), 200);
      }
      // case of update
      else {
        UpdateBranch();
      }
    } else {
      toast.error("Please Fill All Required Fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle select Countries
  const handleSelectCollections = (e) => {
    setSelectedCollections(e);
    if (e.length > 0) {
      var element = document.getElementById("Collections").childNodes[0];
      element.classList.remove("required");
    }
  };

  // Get Selected Collections
  const GetSelectedCollections = async () => {
    let response = await fetch(
      Variables.API_URL + "RestoBranch/GetRestoBranchCollections/" + branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setSelectedCollections(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Get Branch AssignedUsers
  const GetBranchAssignedUsers = async () => {
    let response = await fetch(
      Variables.API_URL +
        "RestoBranchUser/GetBranchAssignedUsers/Restaurant/" +
        restaurantId +
        "/Branch/" +
        branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setAssignedUsers(result);
    }
  };

  // handle select Products
  const handleSelectProducts = (e) => {
    setProductUpdated(true);
    setAssignedProducts(e);
    if (e.length > 0) {
      var element = document.getElementById("Products").childNodes[0];
      element.classList.remove("required");
    } else {
      var element = document.getElementById("Products").childNodes[0];
      element.classList.add("required");
    }
  };

  // handle select Countries
  const handleAssigneUsers = (e) => {
    setUsersUpdated(true);
    setAssignedUsers(e);
    if (e.length > 0) {
      var element = document.getElementById("Users").childNodes[0];
      element.classList.remove("required");
    }
  };

  // Assign Users To Branch
  const AssignUsersToBranch = async () => {
    let response = await fetch(
      Variables.API_URL + "RestoBranchUser/AssignRestoUsersToBranch",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          BranchId: branchId,
          RestaurantId: restaurantId,
          Users: assignedUsers,
        }),
      }
    );
    let result = await response.json();
  };

  // Get Restaurant Product
  const GetRestaurantProductsForDdl = async () => {
    let response = await fetch(
      Variables.API_URL +
        "RestaurantProduct/GetRestaurantProductForDdl/" +
        restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setProducts(result);
    }
  };

  // Assign Products To Branch
  const AssignProductsToBranch = async () => {
    let response = await fetch(
      Variables.API_URL +
        "AssignProductToRestoBranch/AssignRestaurantProductsToBranch",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantId: restaurantId,
          BranchId: branchId,
          Products: assignedProducts,
        }),
      }
    );
    let result = await response.json();
  };

  // Get Branch Assigned Product
  const GetBranchAssignedProducts = async () => {
    let response = await fetch(
      Variables.API_URL +
        "AssignProductToRestoBranch/GetBranchAssignedProducts/Restaurant/" +
        restaurantId +
        "/Branch/" +
        branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setAssignedProducts(result);
    }
  };

  // Get Restaurant Users
  const GetRestaurantUsers = async () => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/GetRestaurantUsers/" + restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setUsers(result);
    }
  };

  //onOpen Time Change
  function onOpenTimeChange(time, timeString) {
    setBranchDetails((prevState) => ({
      ...prevState,
      ["OpenTime"]: timeString,
    }));
  }

  //onClose Time Change
  function onCloseTimeChange(time, timeString) {
    setBranchDetails((prevState) => ({
      ...prevState,
      ["CloseTime"]: timeString,
    }));
  }

  //OnDeliveryStart Time Change
  function onDeliveryStartTimeChange(time, timeString) {
    setBranchDetails((prevState) => ({
      ...prevState,
      ["DeliveryStartTime"]: timeString,
    }));
  }

  //OnDeliveryEnd Time Change
  function onDeliveryEndTimeChange(time, timeString) {
    setBranchDetails((prevState) => ({
      ...prevState,
      ["DeliveryEndTime"]: timeString,
    }));
  }

  useEffect(() => {
    if (restaurantId === 0) {
      navigate("/Restaurant");
    } else {
      if (restaurantId !== 0) {
        GetRestaurantCountries();
        GetRestaurantUsers();
        GetRestaurantProductsForDdl();
        GetCollections();
        if (branchId !== 0) {
          GetBranchDetails();
          GetBranchNumbers();

          GetSelectedCollections();
          GetMedia();
          GetBranchAssignedUsers();
          GetBranchAssignedProducts();
        }
      }
    }
  }, [restaurantId, branchId]);

  console.log("branchDetails", branchDetails);

  return (
    <div>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Branch</h4>
              </div>
              <Button
                variant="secondary"
                onClick={() => navigate("/Restaurant/" + restaurantId)}
              >
                Cancel
              </Button>
            </Card.Header>
            {detailsLoader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="new-user-info">
                  <div className="bd-example">
                    <Tab.Container defaultActiveKey="Details">
                      <Nav>
                        <Nav
                          variant="tabs"
                          className="mb-3"
                          id="nav-tab"
                          role="tablist"
                        >
                          <Nav.Link
                            eventKey="Details"
                            variant=" d-flex align-items-center"
                            id="nav-Details-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Details"
                            type="button"
                            role="tab"
                            aria-controls="nav-Details"
                            aria-selected="true"
                          >
                            Branch Details
                          </Nav.Link>

                          {branchId !== 0 && (
                            <>
                              <Nav.Link
                                eventKey="Delivery"
                                variant=""
                                id="nav-Delivery-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-Delivery"
                                type="button"
                                role="tab"
                                aria-controls="nav-Delivery"
                                aria-selected="false"
                              >
                                Delivery Regions
                              </Nav.Link>

                              <Nav.Link
                                eventKey="Media"
                                variant=""
                                id="nav-Media-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-Media"
                                type="button"
                                role="tab"
                                aria-controls="nav-Media"
                                aria-selected="false"
                              >
                                Media
                              </Nav.Link>
                            </>
                          )}
                        </Nav>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane
                          className=" fade show"
                          eventKey="Details"
                          id="nav-Details"
                          role="tabpanel"
                          aria-labelledby="nav-Details-tab"
                        >
                          <br />
                          <Row>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label htmlFor="fname">Country:</Form.Label>
                              <select
                                className="form-control"
                                name="CountryId"
                                id="CountryId"
                                value={branchDetails.CountryId}
                                onChange={handleCountryChange}
                              >
                                <option key={0} value={0}>
                                  -- Select Country --
                                </option>
                                {restaurantCountries.map((x) => {
                                  return (
                                    <option
                                      key={x.CountryId}
                                      value={x.CountryId}
                                    >
                                      {x.CountryName}
                                    </option>
                                  );
                                })}
                              </select>
                            </Form.Group>

                            <Form.Group className="col-md-6 form-group">
                              <Form.Label htmlFor="fname">City:</Form.Label>
                              <select
                                disabled={
                                  branchDetails.CountryId === 0 ? true : false
                                }
                                className="form-control"
                                name="CityId"
                                id="CityId"
                                value={branchDetails.CityId}
                                onChange={handleCityChange}
                              >
                                {branchDetails.CountryId == 0 ? (
                                  <option key={0} value={0}>
                                    -- Select Country --
                                  </option>
                                ) : (
                                  <option key={0} value={0}>
                                    -- Select City --
                                  </option>
                                )}
                                {cities.map((x) => {
                                  return (
                                    <option key={x.CityId} value={x.CityId}>
                                      {x.CityName}
                                    </option>
                                  );
                                })}
                              </select>
                            </Form.Group>

                            <Form.Group className="col-md-6 form-group">
                              <Form.Label htmlFor="DeliveryCharge">
                                Branch Name :
                              </Form.Label>
                              <Form.Control
                                id="BranchName"
                                name="BranchName"
                                placeholder="Branch Name"
                                type="text"
                                value={branchDetails.BranchName}
                                onChange={handleInputs}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-6 form-group">
                              <Form.Label htmlFor="DeliveryCharge">
                                Delivery Charge :
                              </Form.Label>
                              <Form.Control
                                id="DeliveryCharge"
                                name="DeliveryCharge"
                                placeholder="Delivery Charge"
                                type="text"
                                value={branchDetails.DeliveryCharge}
                                onChange={handleInputs}
                              />
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Label htmlFor="fname">
                                Open Time :
                              </Form.Label>
                              <TimePicker
                                id="OpenTime"
                                use12Hours
                                className="form-control"
                                defaultValue={moment(branchDetails.OpenTime, "HH:mm")}
                                format="HH:mm"
                                onChange={onOpenTimeChange}
                              />
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Label htmlFor="fname">
                                Close Time :
                              </Form.Label>
                              <TimePicker
                                use12Hours
                                className="form-control"
                                defaultValue={moment(branchDetails.CloseTime, "HH:mm")}
                                format="HH:mm"
                                onChange={onCloseTimeChange}
                              />
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Label htmlFor="fname">
                                Delivery Start Time :
                              </Form.Label>
                              <TimePicker
                                id="DeliveryStartTime"
                                use12Hours
                                className="form-control"
                                defaultValue={moment(branchDetails.DeliveryStartTime, "HH:mm")}
                                format="HH:mm"
                                onChange={onDeliveryStartTimeChange}
                              />
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Label htmlFor="fname">
                                Delivery End Time :
                              </Form.Label>
                              <TimePicker
                              id="DeliveryEndTime"
                                use12Hours
                                className="form-control"
                                defaultValue={moment(branchDetails.DeliveryEndTime, "HH:mm")}
                                format="HH:mm"
                                onChange={onDeliveryEndTimeChange}
                              />
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Label htmlFor="fname">Address :</Form.Label>
                              <Form.Control
                                id="Map"
                                name="Map"
                                placeholder="Address"
                                type="text"
                                value={branchDetails.Map}
                                onChange={handleInputs}
                              />
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Label htmlFor="fname">Minimum Order Price :</Form.Label>
                              <Form.Control
                                id="MinimumOrderPrice"
                                name="MinimumOrderPrice"
                                placeholder="Minimum Order Price"
                                type="number"
                                min={0}
                                value={branchDetails.MinimumOrderPrice}
                                onChange={handleInputs}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 form-group">
                              <Form.Label htmlFor="fname">
                                Location :
                              </Form.Label>
                              <BranchLocation
                                branchDetails={branchDetails}
                                setBranchDetails={setBranchDetails}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 form-group">
                              <Form.Label htmlFor="fname">
                                Collections :
                              </Form.Label>
                              <Multiselect
                                options={collections} // Options to display in the dropdown
                                selectedValues={selectedCollections} // Preselected value to persist in dropdown
                                displayValue="CollectionName" // Property name to display in the dropdown options
                                placeholder="Select Collections"
                                id="Collections"
                                name="Collections"
                                onSelect={handleSelectCollections}
                                onRemove={handleSelectCollections}
                              />
                            </Form.Group>
                          </Row>
                          <br />

                          <Row>
                            <b>
                              Phone Numbers :{" "}
                              <button
                                className="btn-icon1"
                                onClick={AddNewPhone}
                              >
                                <FaPlusCircle />
                              </button>
                            </b>
                            <hr style={{ width: "200px" }} className="mt-2" />
                            <br></br>

                            {numbersLoader ? (
                              <CustomSnipper />
                            ) : (
                              <>
                                <Row>
                                  {branchNumbers.map((data, i) => (
                                    <Col key={i} md="6">
                                      <Row>
                                        <Form.Group className="col-sm-10 form-group">
                                          <Form.Control
                                            key={i}
                                            id={"PhoneNumber" + i}
                                            name="PhoneNumber"
                                            placeholder="Phone Number"
                                            type="text"
                                            className="mt-2"
                                            value={data.PhoneNumber}
                                            onChange={(e) =>
                                              handleBranchNumbersInputs(e, i)
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group className="col-sm-2 form-group mt-2">
                                          <Button
                                            className="btn-icon1"
                                            variant="outline-danger"
                                            onClick={() => DeletePhoneNumber(i)}
                                          >
                                            <FaTrashAlt />
                                          </Button>
                                        </Form.Group>
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>
                              </>
                            )}
                          </Row>

                          <br />
                          <Row>
                            <br />
                            <b>Users : </b>
                            <hr style={{ width: "200px" }} className="mt-2" />
                            <br></br>

                            <Form.Group className="col-md-12 form-group">
                              <Form.Label htmlFor="fname">Select :</Form.Label>
                              <Multiselect
                                options={users} // Options to display in the dropdown
                                selectedValues={assignedUsers} // Preselected value to persist in dropdown
                                displayValue="FullName" // Property name to display in the dropdown options
                                placeholder="Assign Users"
                                id="Users"
                                name="Users"
                                onSelect={handleAssigneUsers}
                                onRemove={handleAssigneUsers}
                              />
                            </Form.Group>
                          </Row>

                          <br />
                          <Row>
                            <br />
                            <b>Products : </b>
                            <hr style={{ width: "200px" }} className="mt-2" />
                            <br></br>

                            <Form.Group className="col-md-12 form-group">
                              <Form.Label htmlFor="fname">Select :</Form.Label>
                              <Multiselect
                                options={products} // Options to display in the dropdown
                                selectedValues={assignedProducts} // Preselected value to persist in dropdown
                                displayValue="ProductName" // Property name to display in the dropdown options
                                placeholder="Select Products"
                                id="Products"
                                name="Products"
                                onSelect={handleSelectProducts}
                                onRemove={handleSelectProducts}
                              />
                            </Form.Group>
                          </Row>

                          <div className="float-right">
                            <Button
                              type="button"
                              variant="btn btn-primary"
                              onClick={HandleSumbit}
                            >
                              Submit
                            </Button>{" "}
                            <Button
                              type="button"
                              variant="btn btn-secondary"
                              onClick={() =>
                                navigate("/Restaurant/" + restaurantId)
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </Tab.Pane>

                        {branchId !== 0 && (
                          <>
                            <Tab.Pane
                              className=" fade"
                              id="nav-Delivery"
                              eventKey="Delivery"
                              role="tabpanel"
                              aria-labelledby="nav-Delivery-tab"
                            >
                              <br></br>
                              <BranchDelivery
                                branchId={branchId}
                                mapAttributes={mapAttributes}
                                setMapAttributes={setMapAttributes}
                                branchDetails={branchDetails}
                              />
                            </Tab.Pane>

                            <Tab.Pane
                              className=" fade"
                              id="nav-Media"
                              eventKey="Media"
                              role="tabpanel"
                              aria-labelledby="nav-Media-tab"
                            >
                              <br></br>
                              <Row>
                                <Col>
                                  <Dropzone
                                    onDrop={handleDrop}
                                    accept="image/jpeg,image/png,image/jpg,image/svg"
                                    style={{ height: 100 }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <p>
                                          Drag'n'drop images, or click to select
                                          files
                                        </p>
                                      </div>
                                    )}
                                  </Dropzone>
                                </Col>
                              </Row>

                              {mediaLoader ? (
                                <CustomSnipper />
                              ) : (
                                <>
                                  <Row className="mt-5">
                                    {media.map((data, i) => (
                                      <Col
                                        key={i}
                                        lg="3"
                                        md="4"
                                        sm="6"
                                        className="mb-4 mb-lg-0"
                                      >
                                        <button
                                          className="btn-icon1"
                                          onClick={() =>
                                            setImageModelView(
                                              data.RestoBranchMediaId
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              Variables.imagePath + data.Image
                                            }
                                            className="w-100 shadow-1-strong rounded mb-4"
                                            alt="Boat on Calm Water"
                                          />
                                        </button>
                                        <Modal
                                          show={
                                            data.RestoBranchMediaId ===
                                            imageModelView
                                          }
                                          onHide={() => setImageModelView(0)}
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title as="h5">
                                              Manage Image {data.ImageTitle}
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <Row>
                                              <Col className="text-center">
                                                <Form.Label htmlFor="fname">
                                                  Title:
                                                </Form.Label>
                                                <Form.Control
                                                  id="ImageTitle"
                                                  name="ImageTitle"
                                                  placeholder="Image Title"
                                                  type="text"
                                                  value={data.ImageTitle}
                                                  //onChange={handleInputs}
                                                />
                                              </Col>
                                            </Row>
                                            <Row className="mt-3">
                                              <Col className="text-center">
                                                <Form.Label htmlFor="fname">
                                                  Alt Text :
                                                </Form.Label>
                                                <Form.Control
                                                  id="AltText"
                                                  name="AltText"
                                                  placeholder="Alt Text"
                                                  type="text"
                                                  value={data.AltText}
                                                  //onChange={handleInputs}
                                                />
                                              </Col>
                                            </Row>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              variant="secondary"
                                              onClick={() =>
                                                setImageModelView(0)
                                              }
                                            >
                                              Close
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                setDeleteImageMode(
                                                  data.RestoBranchMediaId
                                                )
                                              }
                                            >
                                              Delete
                                            </Button>
                                            <Button
                                              variant="primary"
                                              //   onClick={() =>
                                              //     PublishUnpublishProduct(
                                              //       tdata.ProductId
                                              //     )
                                              //   }
                                            >
                                              Save changes
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>

                                        <Modal
                                          show={
                                            data.RestoBranchMediaId ===
                                            deleteImageModel
                                          }
                                          onHide={() => setDeleteImageMode(0)}
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title as="h5">
                                              Delete Image {data.ImageTitle}
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <div className="container">
                                              <div className="row">
                                                <p>
                                                  Are you sure you want to this
                                                  image ?
                                                </p>
                                                <img
                                                  src={
                                                    Variables.imagePath +
                                                    data.Image
                                                  }
                                                  className="w-100 shadow-1-strong rounded mb-4"
                                                  alt="Boat on Calm Water"
                                                />
                                              </div>
                                            </div>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              variant="secondary"
                                              onClick={() =>
                                                setDeleteImageMode(0)
                                              }
                                            >
                                              Close
                                            </Button>
                                            <Button
                                              variant="primary"
                                              onClick={() =>
                                                DeleteImage(
                                                  data.RestoBranchMediaId
                                                )
                                              }
                                            >
                                              Confirm Delete
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      </Col>
                                    ))}
                                  </Row>
                                </>
                              )}
                            </Tab.Pane>
                          </>
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ManageBranch;
