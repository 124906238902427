import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Variables } from "../../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSnipper from "../../../components/customSnipper";
import Util from "../../../Classes/Util";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Card,
  Tab,
  TabContainer,
  Tabs,
  Nav,
  FormCheck,
} from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

function RestaurantInfoDetails(props) {
  const navigate = useNavigate();

  const [assignedCountries, setAssignedCountries] = useState([]);

  //Get Assigned countries
  const GetAssignedCountries = async () => {
    let response = await fetch(
      Variables.API_URL + "Country/GetAssignedCountries",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setAssignedCountries(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Set Logo
  const SetLogo = (e) => {
    var x = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      if (
        e.target.result.indexOf("png") >= 0 ||
        e.target.result.indexOf("jpeg") >= 0 ||
        e.target.result.indexOf("jpg") >= 0
      ) {
        props.setLogoToDb(x);
        props.setLogo(e.target.result);
      } else {
        toast.error("File type error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // Set main image
  const SetMainImage = (e) => {
    var x = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      if (
        e.target.result.indexOf("png") >= 0 ||
        e.target.result.indexOf("jpeg") >= 0 ||
        e.target.result.indexOf("jpg") >= 0
      ) {
        props.setMainImageToDb(x);
        props.setMainImage(e.target.result);
      } else {
        toast.error("File type error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // handle Branch Details Inputs
  const handleInputs = (e) => {
    const { name, value } = e.target;

    if (name === "IsDeliveryActive") {
      if (props.restaurantDetails.IsDeliveryActive === true) {
        props.setRestaurantDetails((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        props.setRestaurantDetails((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      }
    } else {
      var element;
      if (value === "" || value == 0) {
        element = document.getElementById(name);
        element.classList.add("is-invalid");
        element.focus();
      } else {
        element = document.getElementById(name);
        element.classList.remove("is-invalid");
      }
      props.setRestaurantDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const HandleActiveDeliveryChange = () => {
    console.log(
      "props.restaurantDetails.IsActiveDelivery",
      props.restaurantDetails.IsDeliveryActive
    );
    if (props.restaurantDetails.IsDeliveryActive === true) {
      props.setRestaurantDetails((prevState) => ({
        ...prevState,
        IsDeliveryActive: false,
      }));
    } else {
      props.setRestaurantDetails((prevState) => ({
        ...prevState,
        IsDeliveryActive: true,
      }));
    }
  };

  // Handle Has Delivery Status Change Permission
  const HandleHasDeliveryStatusChangePermission = () => {
    if (props.restaurantDetails.HasDeliveryStatusChangePermission === true) {
      props.setRestaurantDetails((prevState) => ({
        ...prevState,
        HasDeliveryStatusChangePermission: false,
      }));
    } else {
      props.setRestaurantDetails((prevState) => ({
        ...prevState,
        HasDeliveryStatusChangePermission: true,
      }));
    }
  };

  // handle select Countries
  const handleSelectCountries = (e) => {
    props.setSelectedCountries(e);
    if (e.length > 0) {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.remove("required");
    }
  };

  //Get menu sections
  const GetMenuSections = async () => {
    let response = await fetch(
      Variables.API_URL + "MenuSection/GetMenuSections",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      props.setMenuSections(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Get Selected Menu Sections
  const GetSelectedMenuSections = async () => {
    let response = await fetch(
      Variables.API_URL +
        "RestaurantMenuSection/GetRestaurantMenuSections/" +
        props.restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      props.setSelectedMenuSections(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle select Countries
  const handleSelectMenuSetction = (e) => {
    props.setSelectedMenuSections(e);
    if (e.length > 0) {
      var element = document.getElementById("MenuSections").childNodes[0];
      element.classList.remove("required");
    }
  };

  

  useEffect(() => {
    GetAssignedCountries();
    GetMenuSections();
    GetSelectedMenuSections();
  }, []);
  return (
    <>
      <b>Restaurant Informations : </b>
      <hr />
      <br></br>

      <Row>
        <Col>
          {props.loader ? (
            <CustomSnipper />
          ) : (
            <>
              <Row>
                <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                  <Form.Label htmlFor="fname">Restaurant Name :</Form.Label>
                  <Form.Control
                    id="RestaurantName"
                    name="RestaurantName"
                    placeholder="Restaurant Name"
                    type="text"
                    value={props.restaurantDetails.RestaurantName}
                    onChange={handleInputs}
                  />
                </Form.Group>

                <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                  <Form.Label htmlFor="fname">Average Cost :</Form.Label>
                  <Form.Control
                    id="AverageCost"
                    name="AverageCost"
                    placeholder="ex : 15 000 - 50 000"
                    type="text"
                    value={props.restaurantDetails.AverageCost}
                    onChange={handleInputs}
                  />
                </Form.Group>

                <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                  <Form.Label htmlFor="fname">Due Date :</Form.Label>
                  <Form.Control
                    id="DueDate"
                    name="DueDate"
                    placeholder="About"
                    type="datetime-local"
                    value={props.restaurantDetails.DueDate}
                    onChange={handleInputs}
                  />
                </Form.Group>

                

                <Form.Group className="col-lg-6 col-md-12 col-sm-12 form-group">
                  <Form.Label htmlFor="fname">Menu Sections :</Form.Label>
                  <Multiselect
                    options={props.menuSections} // Options to display in the dropdown
                    selectedValues={props.selectedMenuSections} // Preselected value to persist in dropdown
                    displayValue="MenuSectionName" // Property name to display in the dropdown options
                    placeholder="Select Menu Sections"
                    id="MenuSections"
                    name="MenuSections"
                    onSelect={handleSelectMenuSetction}
                    onRemove={handleSelectMenuSetction}
                  />
                </Form.Group>

                <Form.Group className="col-lg-6 col-md-12 col-sm-12 form-group">
                  <Form.Label htmlFor="fname">Countries :</Form.Label>
                  <Multiselect
                    options={assignedCountries} // Options to display in the dropdown
                    selectedValues={props.selectedCountries} // Preselected value to persist in dropdown
                    displayValue="CountryName" // Property name to display in the dropdown options
                    placeholder="Select Countries"
                    id="Countries"
                    name="Countries"
                    onSelect={handleSelectCountries}
                    onRemove={handleSelectCountries}
                  />
                </Form.Group>

                <div className="col-12">
                  <Row>
                    <Col md="6">
                      <Row>
                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <Form.Label htmlFor="fname">
                            Change Delivery Status Permission :
                          </Form.Label>
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="HasDeliveryStatusChangePermission"
                              checked={
                                props.restaurantDetails
                                  .HasDeliveryStatusChangePermission
                              }
                              onChange={(e) =>
                                HandleHasDeliveryStatusChangePermission()
                              }
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Delivery Status Permission
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <Form.Label htmlFor="fname">
                            Active Delivey :
                          </Form.Label>
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="IsDeliveryActive"
                              checked={props.restaurantDetails.IsDeliveryActive}
                              onChange={(e) => HandleActiveDeliveryChange()}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Active Delivery
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col md="6">
                      {props.restaurantDetails.IsDeliveryActive === false && (
                        <>
                          <Form.Label htmlFor="fname">
                            Delivery Suspension Due Date :
                          </Form.Label>
                          <Form.Control
                            id="DeliverySuspensionDueDate"
                            name="DeliverySuspensionDueDate"
                            placeholder="To .... YYYY-MM-DD"
                            type="datetime-local"
                            value={
                              props.restaurantDetails.DeliverySuspensionDueDate
                            }
                            onChange={handleInputs}
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                </div>

                <Form.Group className="col-lg-6 col-md-12 col-sm-12 form-group">
                  <Row>
                    <Form.Group>
                      <Form.Label className="custom-file-input">
                        Choose Logo
                      </Form.Label>
                      <Form.Control
                        id="Logo"
                        name="Logo"
                        placeholder="Logo"
                        type="file"
                        accept="image/*"
                        onChange={SetLogo}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col className="allignCenter">
                      {props.logo !== "" ? (
                        <div>
                          <img
                            src={props.logo}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Boat on Calm Water"
                          />
                        </div>
                      ) : props.logoPath !== "" && props.logoPath !== null ? (
                        <div>
                          <img
                            src={Variables.imagePath + props.logoPath}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Boat on Calm Water"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="col-lg-6 col-md-12 col-sm-12 form-group">
                  <Row>
                    <Form.Group>
                      <Form.Label className="custom-file-input">
                        Choose Main Image
                      </Form.Label>
                      <Form.Control
                        id="MainImage"
                        name="MainImage"
                        placeholder="Main Image"
                        type="file"
                        accept="image/*"
                        onChange={SetMainImage}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col className="allignCenter">
                      {props.mainImage !== "" ? (
                        <div>
                          <img
                            src={props.mainImage}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Boat on Calm Water"
                          />
                        </div>
                      ) : props.mainIamgePath !== "" &&
                        props.mainIamgePath !== null ? (
                        <div>
                          <img
                            src={Variables.imagePath + props.mainIamgePath}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Boat on Calm Water"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <Form.Label htmlFor="fname">About :</Form.Label>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    id="About"
                    name="About"
                    placeholder="About"
                    type="textarea"
                    value={props.restaurantDetails.About}
                    onChange={handleInputs}
                  ></textarea>
                </Form.Group>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default RestaurantInfoDetails;
