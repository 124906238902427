import React, { useState, useEffect } from "react";
import { Variables } from "../../Variables1/Variables";
import { Link, useNavigate } from "react-router-dom";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Modal, Button, Form, Card } from "react-bootstrap";
import Util from "../../Classes/Util";
import { BiTrashAlt, BiLowVision, BiEditAlt, BiLockAlt } from "react-icons/bi";

function RestaurantsList() {
  const [permissions, setPermissions] = useState(
    Util.decrypt(localStorage.getItem("UserPermissions"))
  );
  const [loader, setLoader] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [lockModelShow, setLockModelShow] = useState(0);
  const [publisheModelShow, setPublishModelShow] = useState(0);
  const navigate = new useNavigate();

  const [search, SetSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Get Restaurant list
  const GetRestaurantList = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Restaurant/GetRestaurantListTbl",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRestaurants(result);
      setFilteredData(result);
    }
    setLoader(false);
  };

  // Lock - Unlock Restaurant
  const LockUnlockRestaurant = async (resId) => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/LockUnlockRestaurant/" + resId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLockModelShow(0);
      GetRestaurantList();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Publish - UUnpublish Restaurant
  const PublishUnpublishRestaurant = async (resId) => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/PublishUnpublishRestaurant/" + resId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPublishModelShow(0);
      GetRestaurantList();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle Search
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    console.log(value);
    SetSearch(value);
    result = restaurants.filter((data) => {
      return data.RestaurantName.toLowerCase().search(value) != -1 || data.OwnerName.toLowerCase().search(value) != -1;
    });
    setFilteredData(result);
  };

  useEffect(() => {
    GetRestaurantList();
  }, []);

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Restaurants List</h4>
              </div>
              {permissions.ViewRestaurants === false ? (
                ""
              ) : (
                <Button
                  variant="primary"
                  onClick={() => navigate("/Restaurant/0")}
                >
                  Add New Restaurant
                </Button>
              )}
            </Card.Header>
            <Card.Body className="px-0">
              <Form.Control
                id="Search"
                name="Search"
                placeholder="Search"
                type="text"
                className="allignCenter"
                onChange={handleSearch}
              />
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Owner</th>
                        <th className="text-center">Branches</th>
                        <th className="text-center">DueDate</th>
                        <th className="text-center">Lock</th>
                        <th className="text-center">Publish</th>
                        <th min-width="100px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((tdata, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            {tdata.RestaurantName}
                          </td>
                          <td className="text-center">{tdata.OwnerName}</td>
                          <td className="text-center">{tdata.BranchesCount}</td>
                          <td className="text-center">{tdata.DueDate}</td>
                          <td className="text-center">
                            <span
                              className={
                                tdata.IsLocked === true
                                  ? "badge bg-danger"
                                  : "badge bg-success"
                              }
                            >
                              {tdata.IsLocked === true ? "Locked" : "UnLocked"}
                            </span>
                          </td>
                          <td className="text-center">
                            <span
                              className={
                                tdata.IsPublished === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {tdata.IsPublished === true
                                ? "Published"
                                : "Unpublished"}
                            </span>
                          </td>
                          <td className="text-center">
                            {permissions.ManageRestaurant === false ? (
                              ""
                            ) : (
                              <div className="flex align-items-center list-user-action">
                                <Link
                                  className="btn btn-sm btn-icon btn-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Lock"
                                  to="#"
                                  onClick={() =>
                                    setLockModelShow(tdata.RestaurantId)}
                                >
                                  <BiLockAlt />
                                </Link>{" "}
                                <Modal
                                  show={tdata.RestaurantId === lockModelShow}
                                  onHide={() => setLockModelShow(0)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title as="h5">
                                      {tdata.IsLocked
                                        ? "Unlock Restaurant"
                                        : "Lock Restaurant"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to{" "}
                                    {tdata.IsLocked ? "Unlock : " : "Lock : "}
                                    <b>{tdata.RestaurantName}</b>?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() => setLockModelShow(0)}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        LockUnlockRestaurant(tdata.RestaurantId)
                                      }
                                    >
                                      Save changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                <Link
                                  className="btn btn-sm btn-icon btn-warning"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Publish"
                                  to="#"
                                  onClick={() =>
                                    setPublishModelShow(tdata.RestaurantId)
                                  }
                                >
                                  <BiLowVision />
                                </Link>{" "}
                                <Modal
                                  show={
                                    tdata.RestaurantId === publisheModelShow
                                  }
                                  onHide={() => setPublishModelShow(0)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title as="h5">
                                      {tdata.IsPublished
                                        ? "Unpublished Restaurant"
                                        : "Published Restaurant"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to{" "}
                                    {tdata.IsPublished
                                      ? "Unpublish : "
                                      : "Publish : "}
                                    <b>{tdata.RestaurantName}</b>?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() => setPublishModelShow(0)}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        PublishUnpublishRestaurant(
                                          tdata.RestaurantId
                                        )
                                      }
                                    >
                                      Save changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                <Link
                                  className="btn btn-sm btn-icon btn-success"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"                                  
                                  to={"/Restaurant/" + tdata.RestaurantId}
                                >
                                  <BiEditAlt />
                                </Link>{" "}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default RestaurantsList;
