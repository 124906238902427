import React, { useState, useEffect, useCallback } from "react";
// import Card from "../../components/Card";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  FormCheck,
  Card,
  Modal,
} from "react-bootstrap";
import Test2 from "../Map/test2";
import { Variables } from "../../Variables1/Variables";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { DatePicker, Space } from "antd";
import { getDay, getMonth, getYear } from "date-fns";

function FeatureRestaurant() {
  const localizer = momentLocalizer(moment);

  const myEventsList = [
    {
      start: new Date(),
      end: new Date(),
      title: "special event",
      id: 1,
      restaurantId: 0,
    },
  ];
  const [restaurantList, setRestaurantList] = useState([]);
  const [newEvent, setNewEvent] = useState({
    start: new Date(),
    end: "",
    title: "",
  });
  const [addModel, setAddModel] = useState(false);
  const [restaurants, setRestaurants] = useState([]);

  /// Get Restaurant List
  const GetRestaurantList = async () => {
    let response = await fetch(
      Variables.API_URL + "RestaurantFeature/GetFeaturedRestaurantsForCalendar",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRestaurantList(result);
    }
  };

  const GetRestaurant = async () => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/GetRestaurantsForDll",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRestaurants(result);
    }
  };

  function OnHideAddModel() {
    setNewEvent({ start: new Date().toISOString(), end: "", title: "" });
    setAddModel(false);
    return false;
  }

  const x = (e) => {
    console.log(e);
    console.log(Date().toLocaleString());
  };

  function handleAddStartTimeChange(e) {
    console.log(new Date(e._d).toISOString());
    newEvent["start"] = new Date(e._d).toISOString();
    setNewEvent(newEvent);
  }

  function handleEndStartTimeChange(e) {
    var x = newEvent;
    x["end"] = new Date(e._d).toISOString();
    setNewEvent(x);
  }

  const handleSelectSlot = useCallback(({ start, end }) => {
    console.log(newEvent);
    setAddModel(true);
  }, []);
  const dateFormat = "YYYY/MM/DD";

  useEffect(() => {
    GetRestaurantList();
    GetRestaurant();
  }, [newEvent]);

  console.log(newEvent);

  return (
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Feature Restaurant</h4>
            </div>
          </Card.Header>
          <Card.Body className="px-0">
            <div className="container-fluid">
              <Calendar
                localizer={localizer}
                events={restaurantList}
                startAccessor="start"
                endAccessor="end"
                defaultDate={Date().toLocaleString()}
                style={{ height: 500 }}
                onDoubleClickEvent={(e) => x(e)}
                onSelectEvent={(e) => x(e)}
                selectable
                onSelectSlot={handleSelectSlot}
              />

              <Modal show={addModel} onHide={OnHideAddModel} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title as="h5">Add New Feature Restaurant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Restaurant :</Form.Label>
                      <select
                        className="form-control"
                        name="MenuSectionId"
                        id="MenuSectionId"
                        //value={productDetails.MenuSectionId}
                        //onChange={HandleInputProductInfo}
                      >
                        <option key={0} value={0}>
                          -- Select Restaurant --
                        </option>
                        {restaurants.map((x) => {
                          return (
                            <option
                              key={x.RestaurantId}
                              value={x.RestaurantId}
                            >
                              {x.RestaurantName}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Group>

                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">Start Date :</Form.Label>
                      <DatePicker
                        className="form-control"
                        defaultValue={moment(newEvent.start, dateFormat)}
                        format={dateFormat}
                        onChange={(e) => handleAddStartTimeChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">End Date :</Form.Label>
                      <DatePicker
                        className="form-control zindex10"
                        defaultValue={
                          newEvent.end === ""
                            ? ""
                            : moment(newEvent.end, dateFormat)
                        }
                        format={dateFormat}
                        onChange={(e) => handleEndStartTimeChange(e)}
                      />
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={OnHideAddModel}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={() => console.log("newi",newEvent)}>Save changes</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default FeatureRestaurant;
