import React, { useState, useEffect } from "react";
import { Row, Col, Image, Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Card from "../../components/Card";
import validator from "validator";
import { Variables } from "../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth2 from "../../assets/images/auth/02.png";
import Util from "../../Classes/Util";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);
  const [passwordFormatError, setPasswordFormatRequiredError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordRequiresError, setConfirmPasswordrequiredError] = useState(false);

  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,})");
  const [loader,setLoader] = useState(false);
  const navigate = new useNavigate();

  //handle password change
  const handlePassword = (e) => {
    const { value } = e.target;
    if (value === "") {
      var element = document.getElementById("NewPassword");
      element.classList.add("is-invalid");
    } else {
      var element = document.getElementById("NewPassword");
      element.classList.remove("is-invalid");
    }
    setPassword(value);
  };

  // handle confirm password
  const handleConfirmPassword = (e) => {
    const {  value } = e.target;
    if (value === "") {
      var element = document.getElementById("ConfirmPassword");
      element.classList.add("is-invalid");
    }
    else{
      var element = document.getElementById("ConfirmPassword");
      element.classList.remove("is-invalid");
    }
    setConfirmPassword(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (password == null || password == "") {
      var element = document.getElementById("NewPassword");
      element.classList.add("is-invalid");
      hasError = true;
    } 
    else if(!strongRegex.test(password)){
      var element = document.getElementById("NewPassword");
      element.classList.add("is-invalid");
        hasError = true;
    }
    else{
      var element = document.getElementById("NewPassword");
      element.classList.remove("is-invalid");
    }

    if(confirmPassword == null || confirmPassword == ""){
      var element = document.getElementById("ConfirmPassword");
      element.classList.add("is-invalid");
        hasError = true;
    }
    else if(password !== confirmPassword){
      var element = document.getElementById("ConfirmPassword");
      element.classList.add("is-invalid");
        hasError = true;
    }
    else{
      var element = document.getElementById("ConfirmPassword");
      element.classList.remove("is-invalid");
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // submit button
  const Submit = async () => {
    var isValid = IsValid();
    if (isValid === true) {
      setLoader(true);
      let response = await fetch(
        Variables.API_URL + "User/UnAuthResetPassword",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Email: email,
            OldPassword: oldPassword,
            NewPassword: password
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success("Your password has been changed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setLoader(false);
          navigate("/");
        }, 2000);
      } else {
        toast.error("Good Nighttt", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setLoader(false);
          navigate("/");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if ("AEMl" in localStorage) {
      setEmail(Util.decrypt(localStorage.getItem("AEMl")));
    } else {
      Navigate("/");
    }
    if ("paOd" in localStorage) {
      setOldPassword(Util.decrypt(localStorage.getItem("paOd")));
    } else {
      Navigate("/");
    }
  }, []);

  return (
    <section className="login-content">
      <Row className="m-0 align-items-center bg-white vh-100">
        <Col
          md="6"
          className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
        >
          <Image
            src={auth2}
            className="img-fluid gradient-main animated-scaleX"
            alt="images"
          />
        </Col>
        <Col md="6" className="p-0">
          <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
            <Card.Body>
              <Link
                to="/"
                className="navbar-brand d-flex align-items-center mb-3"
              >
                <svg
                  width="30"
                  className="text-primary"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-0.757324"
                    y="19.2427"
                    width="28"
                    height="4"
                    rx="2"
                    transform="rotate(-45 -0.757324 19.2427)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.72803"
                    y="27.728"
                    width="28"
                    height="4"
                    rx="2"
                    transform="rotate(-45 7.72803 27.728)"
                    fill="currentColor"
                  />
                  <rect
                    x="10.5366"
                    y="16.3945"
                    width="16"
                    height="4"
                    rx="2"
                    transform="rotate(45 10.5366 16.3945)"
                    fill="currentColor"
                  />
                  <rect
                    x="10.5562"
                    y="-0.556152"
                    width="28"
                    height="4"
                    rx="2"
                    transform="rotate(45 10.5562 -0.556152)"
                    fill="currentColor"
                  />
                </svg>
                <h4 className="logo-title ms-3">PickApp</h4>
              </Link>
              <h2 className="mb-2">Reset Password</h2>
              <p>
                Enter your email address and we'll send you an email with
                instructions to reset your password.
              </p>
              <Form>
                <Row>
                  <Col lg="12" className="col-lg-12">
                    <Form.Group className="floating-label">
                      <Form.Label htmlFor="email" className="form-label">
                        Password
                      </Form.Label>
                      <Form.Control
                        id="NewPassword"
                        name="NewPassword"
                        aria-describedby="New Password"
                        placeholder="New Password"
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={handlePassword}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="col-lg-12 mt-3">
                    <Form.Group className="floating-label">
                      <Form.Label htmlFor="email" className="form-label">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                        aria-describedby="ConfirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        className="form-control"
                        value={confirmPassword}
                        onChange={handleConfirmPassword}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  onClick={() => Submit()}
                  className="mt-3"
                  type="button"
                  variant="primary"
                >
                  {loader === true ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>{" "}
                      {" Reset"}
                    </>
                  ) : (
                    "Reset"
                  )}
                </Button>{" "}
                <Button
                  onClick={() => navigate("/")}
                  className="mt-3"
                  type="button"
                  variant="secondary"
                >
                  Cancel
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <div className="sign-bg sign-bg-right">
            <svg
              width="280"
              height="230"
              viewBox="0 0 431 398"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.05">
                <rect
                  x="-157.085"
                  y="193.773"
                  width="543"
                  height="77.5714"
                  rx="38.7857"
                  transform="rotate(-45 -157.085 193.773)"
                  fill="#3B8AFF"
                />
                <rect
                  x="7.46875"
                  y="358.327"
                  width="543"
                  height="77.5714"
                  rx="38.7857"
                  transform="rotate(-45 7.46875 358.327)"
                  fill="#3B8AFF"
                />
                <rect
                  x="61.9355"
                  y="138.545"
                  width="310.286"
                  height="77.5714"
                  rx="38.7857"
                  transform="rotate(45 61.9355 138.545)"
                  fill="#3B8AFF"
                />
                <rect
                  x="62.3154"
                  y="-190.173"
                  width="543"
                  height="77.5714"
                  rx="38.7857"
                  transform="rotate(45 62.3154 -190.173)"
                  fill="#3B8AFF"
                />
              </g>
            </svg>
          </div>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default ResetPassword;
