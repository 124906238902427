import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Variables } from "../../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSnipper from "../../../components/customSnipper";

function MenuImages(props) {
  const [loader, setLoader] = useState(false);
  const [DeleteModel, SetDeleteModel] = useState(0);

  // Get Menu Images
  const GetMenuImages = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestaurantMenuImage/GetRestaurantMenuImages/" +
        props.restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();

    if (response.status === 200) {
      props.setMenuImages(result);
    } else {
    }
    setLoader(false);
  };

  // Delete Menu Image
  const DeleteMenuImage = async (imageId) => {
    let response = await fetch(
      Variables.API_URL +
        "RestaurantMenuImage/DeleteRestaurantMenuImage/" +
        imageId,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      GetMenuImages();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Drop
  const handleDrop = async (files) => {
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          formData.append("RestaurantId", props.restaurantId);
          try {
            const res = await axios.post(
              Variables.API_URL +
                "RestaurantMenuImage/UploadRestaurantMenuImage",
              formData
            );
            if (res.data !== "") {
              await fetch(
                Variables.API_URL +
                  "RestaurantMenuImage/AddRestauraurantMenuImageToDb",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    RestaurantId: props.restaurantId,
                    path: res.data,
                  }),
                }
              ).then((Response) => {
                if (Response.ok) {
                  GetMenuImages();
                } else {
                  console.log(Response);
                }
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  useEffect(() => {
    if (props.restaurantId !== 0) {
      GetMenuImages();
    }
  }, [props.restaurantId]);

  return (
    <div>
      <br></br>
      <Row>
        <Col>
          <Dropzone
            onDrop={handleDrop}
            accept="image/jpeg,image/png,image/jpg,image/svg"
            style={{ height: 100 }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag'n'drop images, or click to select files</p>
              </div>
            )}
          </Dropzone>
        </Col>
      </Row>

      {loader ? (
        <CustomSnipper />
      ) : (
        <>
          <Row className="mt-5">
            {props.menuImages.map((data, i) => (
              <Col key={i} lg="3" md="4" sm="6" className="mb-4 mb-lg-0">
                <Button
                  className="btn-icon"
                  variant="outline-light"
                  onClick={() => SetDeleteModel(data.RestaurantMenuImageId)}
                >
                  <img
                    src={Variables.imagePath + data.ImageUrl}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Boat on Calm Water"
                  />
                </Button>
                <Modal
                  show={data.RestaurantMenuImageId === DeleteModel}
                  onHide={() => SetDeleteModel(0)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title as="h5">Delete Menu Image</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container">
                      Are you sure to delete this menu image ?
                      <img
                        src={Variables.imagePath + data.ImageUrl}
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => SetDeleteModel(0)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        DeleteMenuImage(data.RestaurantMenuImageId)
                      }
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
}

export default MenuImages;
