import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Variables } from "../../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSnipper from "../../../components/customSnipper";
import Util from "../../../Classes/Util";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Card,
  Tab,
  TabContainer,
  Tabs,
  Nav,
} from "react-bootstrap";

function OwnerInfoDetails(props) {
  const [genders, setGenders] = useState([]);

  // Get Genders
  const GetGenders = async () => {
    let response = await fetch(Variables.API_URL + "Gender/GetGenders", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setGenders(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle Branch Details Inputs
  const handleInputs = (e) => {
    const { name, value } = e.target;

    var element;
    if (value === "" || value == 0) {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
      element.focus();
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    props.setOwnerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    GetGenders();
  }, []);

  return (
    <div>
      <b>Owner Informations : </b>
      <hr />

      <br></br>

      <Row>
        {props.loader ? (
          <CustomSnipper />
        ) : (
          <Col>
            <Row>
              <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                <Form.Label htmlFor="fname">First Name :</Form.Label>
                <Form.Control
                  id="FirstName"
                  name="FirstName"
                  placeholder="First Name"
                  type="text"
                  disabled={props.restaurantId !== 0 ? true : false}
                  value={props.ownerInfo.FirstName}
                  onChange={handleInputs}
                />
              </Form.Group>

              <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                <Form.Label htmlFor="fname">Last Name :</Form.Label>
                <Form.Control
                  id="LastName"
                  name="LastName"
                  placeholder="Last Name"
                  type="text"
                  value={props.ownerInfo.LastName}
                  disabled={props.restaurantId !== 0 ? true : false}
                  onChange={handleInputs}
                />
              </Form.Group>

              <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                <Form.Label htmlFor="fname">Gender :</Form.Label>
                <select
                    className="form-control"
                    name="GenderId"
                    id="GenderId"
                    value={props.ownerInfo.GenderId}
                    disabled={props.restaurantId !== 0 ? true : false}
                    onChange={handleInputs}
                  >
                    <option key={0} value={0}>
                      -- Select --
                    </option>
                    {genders.map((x) => {
                      return (
                        <option key={x.GenderId} value={x.GenderId}>
                          {x.Name}
                        </option>
                      );
                    })}
                  </select>
              </Form.Group>

              <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                <Form.Label htmlFor="fname">Email :</Form.Label>
                <Form.Control
                  id="Email"
                  name="Email"
                  placeholder="Email"
                  type="text"
                  value={props.ownerInfo.Email}
                  disabled={props.restaurantId !== 0 ? true : false}
                  onChange={handleInputs}
                />
              </Form.Group>

              <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                <Form.Label htmlFor="fname">Phone Number :</Form.Label>
                <Form.Control
                  id="PhoneNumber"
                  name="PhoneNumber"
                  placeholder="Phone Number"
                  type="text"
                  value={props.ownerInfo.PhoneNumber}
                  disabled={props.restaurantId !== 0 ? true : false}
                  onChange={handleInputs}
                />
              </Form.Group>

            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default OwnerInfoDetails;
