import React, { useState, useEffect } from "react";
import { Variables } from "../../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";
import axios from "axios";
import CustomSnipper from "../../../components/customSnipper";
import { Row, Col, Modal, Button } from "react-bootstrap";

function RestaurantMedia(props) {
  const [GeneralMedia, setGeneralMedia] = useState([]);
  const [generalMediaLoader, setGeneralMediaLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [imageModelView, setImageModelView] = useState(0);
  const [deleteImageModel, setDeleteImageModel] = useState(0);

  // Handle Drop
  const handleDrop = async (files) => {
    setUploadLoader(true);
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          formData.append("RestaurantId", props.restaurantId);
          formData.append("FolderPath", "");
          try {
            const res = await axios.post(
              Variables.API_URL + "Restaurant/UploadGeneralRestoMedia",
              formData
            );
            if (res.data !== "") {
              await fetch(
                Variables.API_URL + "Restaurant/UploadGeneralRestoMediaToDb",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    RestaurantId: props.restaurantId,
                    path: res.data,
                  }),
                }
              ).then((Response) => {
                if (Response.ok) {
                  GetRestaurantGeneralMedia();
                } else {
                }
              });
            }
          } catch (ex) {}
        };
        reader.readAsDataURL(file);
      }
    }

    setUploadLoader(false);
    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  // Get General Media
  const GetRestaurantGeneralMedia = async () => {
    setGeneralMediaLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "Restaurant/GetRestaurantGeneralMedia/" +
        props.restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setGeneralMedia(result);
    }
    setGeneralMediaLoader(false);
  };

  const DeleteImage = async (imageId) => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/DeleteRestaurantGeneralMedia/" + imageId,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      GetRestaurantGeneralMedia();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    GetRestaurantGeneralMedia();
  }, []);
  return (
    <div>
      <br></br>
      <Row>
        {uploadLoader ? <CustomSnipper /> : null}
        <Col>
          <Dropzone
            onDrop={handleDrop}
            accept="image/jpeg,image/png,image/jpg,image/svg"
            style={{ height: 100 }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag'n'drop images, or click to select files</p>
              </div>
            )}
          </Dropzone>
        </Col>
      </Row>

      <Row className="mt-5">
        {GeneralMedia.map((data, i) => (
          <Col key={i} lg="3" md="4" sm="6" className="mb-4 mb-lg-0">
            <Button
              className="btn-icon"
              variant="outline-light"
              onClick={() => setDeleteImageModel(data.RestaurantMediaId)}
            >
              <img
                src={Variables.imagePath + data.Image}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />
            </Button>
            <Modal
              show={data.RestaurantMediaId === deleteImageModel}
              onHide={() => setDeleteImageModel(0)}
            >
              <Modal.Header closeButton>
                <Modal.Title as="h5">Delete Image {data.ImageTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                <p>Are you sure you want to this image ?</p>
                            <img
                              src={Variables.imagePath + data.Image}
                              className="w-100 shadow-1-strong rounded mb-4"
                              alt="Boat on Calm Water"
                            />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setDeleteImageModel(0)}>
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={() =>
                    DeleteImage(data.RestaurantMediaId)
                  }
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default RestaurantMedia;
