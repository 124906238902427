import React from "react";
import { Routes, Route } from "react-router-dom";

import ConfirmMail from "../views/dashboard/auth/confirm-mail";
import LockScreen from "../views/dashboard/auth/lock-screen";
// import Recoverpw from "../views/dashboard/auth/recoverpw";
import Recoverpw from "../templates/Login/recoverpw";
import SignIn from "../templates/Login/sign-in";
import SignUp from "../views/dashboard/auth/sign-up";
import ResetPassword from "../templates/Login/ResetPassword";
import Error404 from "../views/dashboard/errors/error404";

const PublicRoutes = (props) => {
  return (
    <>
      <Routes>
        {/* auth */}
        <Route exact path="/auth/confirm-mail" element={<ConfirmMail />} />
        <Route exact path="/auth/lock-screen" element={<LockScreen />} />
        <Route exact path="/forgetPassword" element={<Recoverpw />} />
        <Route exact path="/ResetPassword" element={<ResetPassword />} />

        <Route exact path="/auth/sign-in" element={<SignIn setIsAuth={props.setIsAuth}/>} />
        <Route
          exact
          path="/"
          element={<SignIn setIsAuth={props.setIsAuth} />}
        />
        <Route exact path="*" element={<SignIn setIsAuth={props.setIsAuth} />} />
        <Route exact path="/auth/sign-up" element={<SignUp />} />
        {/* error */}
        {/* <Route exact path="/errors/error404" element={Error404} />
          <Route exact path="/errors/error500" element={Error500} />
          <Route exact path="/errors/maintenance" element={Maintenance} /> */}
      </Routes>
    </>
  );
};

export default PublicRoutes;
