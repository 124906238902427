import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Variables } from "../../Variables1/Variables";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Image, Form, Button, Spinner } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomSnipper from "../../components/customSnipper";
import Util from "../../Classes/Util";

function ManageUsers() {
  const UserRouteId = useParams();
  const [permissions, setPermissions] = useState(
    Util.decrypt(localStorage.getItem("UserPermissions"))
  );
  const [userId, setUserId] = useState(Number(UserRouteId.UserRouteId) || 0);
  const [userDetails, setUserDetails] = useState({
    FirstName: "",
    LastName: "",
    GenderId: 0,
    RoleId: 0,
    Email: "",
    PhoneNumber: "",
  });
  const [genders, setGenders] = useState([]);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [buttonLoader,setButtonLoader] = useState(false);

  // Get User Details
  const GetUserDetails = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "User/GetUserById/" + userId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      console.log("yes");
      setUserDetails(result);
    } else {
      navigate("/User");
    }
    setLoader(false);
  };

  // Get Genders
  const GetGenders = async () => {
    let response = await fetch(Variables.API_URL + "Gender/GetGenders", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setGenders(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Get Roles
  const GetRoles = async () => {
    let response = await fetch(
      Variables.API_URL + "UserRole/GetUserRolesForDdl",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRoles(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Add new user
  const AddNewUser = async () => {
    setButtonLoader(true);
    let response = await fetch(Variables.API_URL + "User/AddNewUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        FirstName: userDetails.FirstName,
        LastName: userDetails.LastName,
        GenderId: userDetails.GenderID,
        RoleId: userDetails.RoleId,
        Email: userDetails.Email,
        PhoneNumber: userDetails.PhoneNumber,
      }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/User");
      }, 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setButtonLoader(false);
  };

  // Update user
  const UpdateUser = async () => {
    setButtonLoader(true)
    let response = await fetch(Variables.API_URL + "User/UpdateUser", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserId: userId,
        FirstName: userDetails.FirstName,
        LastName: userDetails.LastName,
        GenderId: userDetails.GenderId,
        RoleId: userDetails.RoleId,
        Email: userDetails.Email,
        PhoneNumber: userDetails.PhoneNumber,
      }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setButtonLoader(false);
  };

  // handle Inputs
  const handleInputs = (e) => {
    const { name, value } = e.target;
    var element;
    if (value === "" || value === "0") {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Check input validity
  function IsValid() {
    var hasError = false;
    var element;
    if (userDetails.FirstName === null || userDetails.FirstName === "") {
      element = document.getElementById("FirstName");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (userDetails.LastName === null || userDetails.LastName === "") {
      element = document.getElementById("LastName");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (
      userDetails.GenderId === null ||
      userDetails.GenderId === "0" ||
      userDetails.GenderId === 0
    ) {
      element = document.getElementById("GenderId");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (
      userDetails.RoleId === null ||
      userDetails.RoleId === "0" ||
      userDetails.RoleId === 0
    ) {
      element = document.getElementById("RoleId");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (
      userDetails.Email === null ||
      userDetails.Email === "" ||
      validator.isEmail(userDetails.Email) === false
    ) {
      element = document.getElementById("Email");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (userDetails.PhoneNumber === null || userDetails.PhoneNumber === "") {
      element = document.getElementById("PhoneNumber");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // handle submit
  const HandleSubmit = () => {
    var isValid = IsValid();
    if (isValid) {
      // case of insert
      if (userId === 0) {
        AddNewUser();
      }
      // case of update
      else {
        UpdateUser();
      }
    } else {
      toast.error("Please fill all required fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if(permissions.ManageUser === false){
      navigate("/");
    }
    if (userId !== 0) {
      GetUserDetails();
    }
    GetGenders();
    GetRoles();
  }, []);


  return (
    <div>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">New User Information</h4>
              </div>
            </Card.Header>
            {loader === true ? <CustomSnipper /> : ""}
            <Card.Body>
              <div className="new-user-info">
                <form>
                  <div className="row">
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">First Name:</Form.Label>
                      <Form.Control
                        id="FirstName"
                        name="FirstName"
                        placeholder="First Name"
                        type="text"
                        value={userDetails.FirstName}
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="lname">Last Name:</Form.Label>
                      <Form.Control
                        id="LastName"
                        name="LastName"
                        placeholder="Last Name"
                        type="text"
                        value={userDetails.LastName}
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group className="col-sm-6 form-group">
                      <Form.Label>Gender:</Form.Label>
                      <select
                        className="selectpicker form-control"
                        data-style="py-0"
                        name="GenderId"
                        id="GenderId"
                        value={userDetails.GenderId}
                        onChange={handleInputs}
                      >
                        <option key={0} value={0}>
                          -- Select --
                        </option>
                        {genders.map((x) => {
                          return (
                            <option key={x.GenderId} value={x.GenderId}>
                              {x.Name}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Group>

                    <Form.Group className="col-sm-6 form-group">
                      <Form.Label>Role:</Form.Label>
                      <select
                        className="selectpicker form-control"
                        data-style="py-0"
                        name="RoleId"
                        id="RoleId"
                        value={userDetails.RoleId}
                        onChange={handleInputs}
                      >
                        <option key={0} value={0}>
                          -- Select --
                        </option>
                        {roles.map((x) => {
                          return (
                            <option key={x.RoleId} value={x.RoleId}>
                              {x.Name}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Group>

                    <Form.Group className="col-md-6  form-group">
                      <Form.Label htmlFor="email">Email:</Form.Label>
                      <Form.Control
                        disabled={userId === 0 ? false : true}
                        id="Email"
                        name="Email"
                        placeholder="Email"
                        type="text"
                        value={userDetails.Email}
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6  form-group">
                      <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                      <Form.Control
                        id="PhoneNumber"
                        name="PhoneNumber"
                        placeholder="Phone Number"
                        type="text"
                        value={userDetails.PhoneNumber}
                        onChange={handleInputs}
                      />
                    </Form.Group>
                  </div>
                </form>
              </div>
              <Row>
                <Col>
                  <Button
                    type="button"
                    variant="btn btn-primary"
                    className="float-right"
                    onClick={HandleSubmit}
                  >
                    {buttonLoader === true ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : userId === 0 ? (
                      "Add New User"
                    ) : (
                      "Update User"
                    )}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ManageUsers;
