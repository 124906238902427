import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";
import CustomSnipper from "../../../components/customSnipper";
import { Row, Col, Form, Button } from "react-bootstrap";
import { options } from "preact";
import { Variables } from "../../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateLocale } from "moment";

function arePointsNear(checkPoint, centerPoint, km) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
}

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD5uxW4zqjmJCKgI7v_JyyNEycRx76iZbs&vv=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <>
    <GoogleMap
      onDblClick={(ev) => {
        if (props.circleItteration === 1000000) {
          var array = [...props.mapAttributes];

          array.push({
            BranchID: props.branchId,
            lat: ev.latLng.lat(),
            lng: ev.latLng.lng(),
            Radius: 1000,
          });
          props.setMapAttributes(array);
          props.setCircleItteration(array.length - 1);
          props.setSliderValue(1000);
        } else {
          props.setSliderValue(0);
          props.setCircleItteration(1000000);
        }

        //   var x = arePointsNear(
        //     { lat: ev.latLng.lat(), lng: ev.latLng.lng() },
        //     { lat: 33.871594256384945, lng: 35.50831362537152 },
        //     1
        //   );
      }}

      onClick={(ev) => {
        if (props.circleItteration !== 1000000) {
            props.setSliderValue(0);
            props.setCircleItteration(1000000);
        }}}
      defaultZoom={12}
      defaultCenter={{
        lat: props.branchDetails.Lat,
        lng: props.branchDetails.Lng,
      }}
      onGoogleApiLoaded={({ map, maps }) =>
        new maps.Circle({
          strokeColor: "#FFFFFF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFFFFF",
          fillOpacity: 0.3,
          map,
          center: { lat: "33.871594256384945", lng: "35.50831362537152" },
          radius: 275,
        })
      }
    >
      <Marker
        position={{
          lat: props.branchDetails.Lat,
          lng: props.branchDetails.Lng,
        }}
      />
      {props.mapAttributes.map((data, i) => (
        <Circle
          key={i}
          strokeColor="#a84432"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#a84432"
          fillOpacity={0.35}
          center={props.mapAttributes[i]}
          radius={props.mapAttributes[i].Radius}
          options={{
            fillColor: props.circleItteration === i ? "#61b3c2" : "#ffffff",
            strokeColor: "#8f8989",
          }}
          draggable={props.circleItteration !== 1000000 ? true : false}
          onDragEnd={(event) => {
            var array = [...props.mapAttributes];
            var itterationToUpdate = array[i];
            itterationToUpdate.lat = event.latLng.lat();
            itterationToUpdate.lng = event.latLng.lng();
            props.setMapAttributes(array);
          }}
          onClick={(x) => {
            if (props.circleItteration === i) {
              props.setCircleItteration(1000000);
              props.setSliderValue(0);
            } else {
              props.setCircleItteration(i);
              props.setSliderValue(data.Radius);
            }
          }}
        />
      ))}
    </GoogleMap>
  </>
));

function BranchDeliveryMap(props) {
  const [circleItteration, setCircleItteration] = useState(1000000);
  const [sliderValue, setSliderValue] = useState(0);
  const [resetLoader, setResetLoader] = useState(false);

  const UpdateLocs1 = async () => {
    console.log("in");
    let response = await fetch(
      Variables.API_URL + "RestoBranch/AddRestoBranchDeliveryLoc",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          BranchId: props.branchId,
          Locations: props.mapAttributes,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    console.log("out");
  };

  const SliderRadiusChange = (val) => {
    if (circleItteration !== 1000000) {
      var array = [...props.mapAttributes];
      var itterationToUpdate = array[circleItteration];
      itterationToUpdate.Radius = val;
      props.setMapAttributes(array);
      setSliderValue(val);
    }
  };

  const DeleteCircle = () => {
    var array = [...props.mapAttributes];
    array.splice(circleItteration, 1);
    props.setMapAttributes(array);
    setCircleItteration(1000000);
    setSliderValue(0);
  };

  // Get Branch Locations
  const GetBranchLocs = async () => {
    setResetLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/GetBranchDeliveryLocation/" +
        props.branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      console.log("fetch", result);
      props.setMapAttributes(result);
    }
    setResetLoader(false);
  };

  useEffect(() => {}, [props.mapAttributes, circleItteration, sliderValue]);

  console.log("radius", sliderValue / 100);

  return (
    <>
      <Row>
        <Col lg="9" md="9" sm="12">
          <b>radius (x100 meter) : </b>
          <Form.Group>
            <Form.Range
              value={sliderValue !== 0 ? sliderValue / 100 : 0}
              disabled={circleItteration === 1000000}
              //defaultValue={sliderValue !== 0 ? sliderValue / 100 : 0}
              onChange={(e) => SliderRadiusChange(e.target.value * 100)}
            />
          </Form.Group>
        </Col>
        <Col lg="1" md="1" sm="12">
          <Button variant="outline-warning" onClick={() => GetBranchLocs()}>
            reset
          </Button>
        </Col>
        <Col lg="1" md="1" sm="12">
          <Button variant="outline-success" onClick={() => UpdateLocs1()}>
            Save
          </Button>
        </Col>
        <Col lg="1" md="1" sm="12">
          <Button
            variant="outline-danger"
            disabled={circleItteration === 1000000}
            onClick={() => DeleteCircle()}
          >
            Delete
          </Button>
        </Col>
      </Row>
      {resetLoader === true ? (
        <CustomSnipper />
      ) : (
        <MyMapComponent
          setMapAttributes={props.setMapAttributes}
          mapAttributes={props.mapAttributes}
          customRadius={props.customRadius}
          setCustomRadius={props.setCustomRadius}
          setCircleItteration={setCircleItteration}
          circleItteration={circleItteration}
          branchId={props.branchId}
          setSliderValue={setSliderValue}
          branchDetails={props.branchDetails}
        />
      )}
    </>
  );
}

export default BranchDeliveryMap;
