import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { Variables } from "../../../Variables1/Variables";
import CustomSnipper from "../../../components/customSnipper";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Card,
  Tab,
  TabContainer,
  Tabs,
  Nav,
  Spinner,
} from "react-bootstrap";
import { BiTrashAlt, BiLowVision, BiEditAlt } from "react-icons/bi";

function BranchesList(props) {
  const [loader, setLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [activeModelShow, setActiveModelShow] = useState(0);
  const navigate = useNavigate();

  // Get Branches List
  const GetBranchesList = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/GetRestaurantBranches/" +
        props.restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setBranches(result);
    }
    setLoader(false);
  };

  // Active Deactivate Branch
  const ActiveDeactivateBranch = async (branch) => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/ActiveDeactiveBranch/Restaurant/" +
        props.restaurantId +
        "/Branch/" +
        branch,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      GetBranchesList();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
    setActiveModelShow(0);
    setLoader(false);
  };

  useEffect(() => {
    GetBranchesList();
  }, []);

  console.log(branches)
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Branches List</h4>
              </div>
              <Button
                variant="primary"
                onClick={() => navigate("/Branch/0/Restaurant/" + props.restaurantId)}
              >
                Add New Branch
              </Button>
            </Card.Header>
            <Card.Body className="px-0">
              {/* <Form.Control
                id="Search"
                name="Search"
                placeholder="Search"
                type="text"
                className="allignCenter"
                onChange={handleSearch}
              /> */}
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Country</th>
                        <th className="text-center">City</th>
                        <th className="text-center">Active</th>
                        <th min-width="100px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {branches.map((tdata, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{tdata.BranchName}</td>
                          <td className="text-center">{tdata.CountryName}</td>
                          <td className="text-center">{tdata.CityName}</td>
                          <td className="text-center">
                            <span
                              className={
                                tdata.IsActive === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {tdata.IsActive === true ? "Active" : "Deactive"}
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="flex align-items-center list-user-action">
                              <Link
                                className="btn btn-sm btn-icon btn-warning"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Lock"
                                to="#"
                                onClick={() =>
                                  setActiveModelShow(tdata.BranchId)
                                }
                              >
                                <span className="btn-inner">
                                  <BiLowVision />
                                </span>
                              </Link>{" "}
                              <Modal
                                show={tdata.BranchId === activeModelShow}
                                onHide={() => setActiveModelShow(0)}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title as="h5">
                                    {tdata.IsActive
                                      ? "Unactive Branch"
                                      : "Deactive Branch"}
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Are you sure you want to{" "}
                                  {tdata.IsActive
                                    ? "Deactivate : "
                                    : "Avtivate : "}
                                  Branch?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setActiveModelShow(0)}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      ActiveDeactivateBranch(tdata.BranchId)
                                    }
                                  >
                                    Save changes
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              <Link
                                className="btn btn-sm btn-icon btn-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to={
                                  "/Branch/" +
                                  tdata.BranchId +
                                  "/Restaurant/" +
                                  props.restaurantId
                                }
                              >
                                <span className="btn-inner">
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M15.1655 4.60254L19.7315 9.16854"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </Link>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default BranchesList;
