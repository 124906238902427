import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Variables } from "../../Variables1/Variables";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import { BiTrashAlt,BiLowVision,BiEditAlt } from "react-icons/bi";

function MenusList() {
  const [permissions, setPermissions] = useState(
    Util.decrypt(localStorage.getItem("UserPermissions"))
  );
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [menus, setMenus] = useState([]);
  const [publishModel, setPublishModel] = useState(0);
  const [deleteModel, setDeleteModel] = useState(0);
  const [editModel, setEditModel] = useState(0);
  const [addModel, setAddModel] = useState(false);
  const [menuUpdate, setMenuUpdate] = useState({
    MenuSectionId: 0,
    MenuSectionName: "",
  });
  const [idToEdit, setIdToEdit] = useState(0);
  const [nameToEdit, setNameToEdit] = useState("");
  const [nameToAdd, setNameToAdd] = useState("");
  const [search, SetSearch] = useState("");
  const [filteredMenu, setFilteredMenu] = useState([]);

  // Get Menus list
  const GetMenus = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "MenuSection/GetMenuSections",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setMenus(result);
      setFilteredMenu(result);
    }
    setLoader(false);
  };

  // Publish - Unpublish menu
  const PublishUnpublishMenu = async (mId) => {
    let response = await fetch(
      Variables.API_URL + "MenuSection/PublishUnpublishMenuSection/" + mId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPublishModel(0);
      GetMenus();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Delete menu
  const DeleteMenu = async (mId) => {
    let response = await fetch(
      Variables.API_URL + "MenuSection/DeleteMenuSection/" + mId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDeleteModel(0);
      GetMenus();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const SetUpdate = (colId, colName) => {
    setIdToEdit(colId);
    setNameToEdit(colName);
    setEditModel(colId);
  };

  function OnHideEditModel() {
    setIdToEdit(0);
    setNameToEdit("");
    setEditModel(0);
    return false;
  }

  function OnHideAddModel() {
    setNameToAdd("");
    setAddModel(false);
    return false;
  }

  // handle Search
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    console.log(value);
    SetSearch(value);
    result = menus.filter((data) => {
      return data.MenuSectionName.toLowerCase().search(value) != -1;
    });
    setFilteredMenu(result);
  };

  // handle  Inputs t o edit
  const handleInputs = (e) => {
    const { name, value } = e.target;

    var element;
    if (value === "" || value == null) {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setNameToEdit(value);
  };

  // Is Valid To Edit
  function IsValidToEdit() {
    var hasError = false;

    if (nameToEdit === "" || nameToEdit === null) {
      var element = document.getElementById("MenuNameToEdit");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (idToEdit === 0 || idToEdit === null) {
      hasError = true;
    }
    if (hasError) {
      return false;
    } else {
      return true;
    }
  }

  // Handle Insert
  const HandleUpdate = async () => {
    var valid = IsValidToEdit();
    if (valid) {
      let response = await fetch(
        Variables.API_URL + "MenuSection/UpdateMenuSection",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            MenuSectionId: idToEdit,
            MenuSectionName: nameToEdit,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        GetMenus();
        setIdToEdit(0);
        setNameToEdit("");
        setEditModel(false);
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please select name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle  Inputs TO Add
  const handleInputsToADD = (e) => {
    const { name, value } = e.target;

    var element;
    if (value === "" || value == null) {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setNameToAdd(value);
  };

  // Is Valid To Add
  function IsValidToAdd() {
    var hasError = false;

    if (nameToAdd === "" || nameToAdd === null) {
      var element = document.getElementById("MenuNameToAdd");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (hasError) {
      return false;
    } else {
      return true;
    }
  }

  // Handle Insert
  const HandleInsert = async (e) => {
    e.preventDefault();

    var valid = IsValidToAdd();
    if (valid == true) {
      let response = await fetch(
        Variables.API_URL + "MenuSection/AddNewMenuSection",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            MenuSectionName: nameToAdd,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddModel(false);
        GetMenus();
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please Select Name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
      if(permissions.ViewMenus === false){
          navigate("/");
      }
    GetMenus();
  }, []);

  return <div>
  <Row>
    <Col sm="12">
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">User List</h4>
          </div>
          {permissions.ManageMenus === false ? (
            ""
          ) : (
            <Button variant="primary" onClick={() => setAddModel(true)}>
              Add New Menu
            </Button>
          )}
        </Card.Header>
        <Card.Body className="px-0">
          <Form.Control
            id="Search"
            name="Search"
            placeholder="Search"
            type="text"
            className="allignCenter"
            onChange={handleSearch}
          />
          {loader === true ? (
            <CustomSnipper />
          ) : (
            <div className="table-responsive">
              <table
                id="user-list-table"
                className="table table-striped"
                role="grid"
                data-toggle="data-table"
              >
                <thead>
                  <tr className="ligth">
                    <th></th>
                    <th>Name</th>
                    <th>Publish</th>
                    <th min-width="100px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMenu.map((tdata, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{tdata.MenuSectionName}</td>
                      <td>
                        <span
                          className={
                            tdata.IsPublished === true
                              ? "badge bg-success"
                              : "badge bg-danger"
                          }
                        >
                          {tdata.IsPublished === true
                            ? "Published"
                            : "Unpublished"}
                        </span>
                      </td>
                      <td>
                        {permissions.ManageCollections === false ? (
                          ""
                        ) : (
                          <div className="flex align-items-center list-user-action">
                              {/* Publish Button */}
                            <Link
                              className="btn btn-sm btn-icon btn-warning"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Publish"
                              to="#"
                              onClick={() =>
                                setPublishModel(tdata.MenuSectionId)
                              }
                            >
                              <span className="btn-inner">
                                <BiLowVision/>
                              </span>
                            </Link>{" "}
                            <Modal
                              show={tdata.MenuSectionId === publishModel}
                              onHide={() => setPublishModel(0)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title as="h5">
                                {tdata.IsPublished
                                  ? "Unpublished Menu"
                                  : "Published Menu"}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                              Are you sure you want to{" "}
                                    {tdata.IsPublished
                                      ? "Unpublish : "
                                      : "Publish : "}
                                    <b>{tdata.MenuSectionName}</b>?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={() => setPublishModel(0)}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    PublishUnpublishMenu(
                                      tdata.MenuSectionId
                                    )
                                  }
                                >
                                  Save changes
                                </Button>
                              </Modal.Footer>
                            </Modal>

                            {/* Delete Button */}
                            <Link
                              className="btn btn-sm btn-icon btn-danger"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Publish"
                              to="#"
                              onClick={() => setDeleteModel(tdata.MenuSectionId)}
                            >
                              <span className="btn-inner">
                                <BiTrashAlt/>
                              </span>
                            </Link>{" "}
                            <Modal
                              show={tdata.MenuSectionId === deleteModel}
                              onHide={() => setDeleteModel(0)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title as="h5">
                                Delete Menu
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                              Are you sure you want to Delete{" "}
                                    <b>{tdata.MenuSectionName}</b>?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={() => setDeleteModel(0)}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    DeleteMenu(tdata.MenuSectionId)
                                  }
                                >
                                  Save changes
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Link
                              className="btn btn-sm btn-icon btn-success"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Edit"
                              onClick={() =>
                                SetUpdate(
                                  tdata.MenuSectionId,
                                  tdata.MenuSectionName
                                )
                              }
                              to={"#"}
                            >
                              <span className="btn-inner">
                                <BiEditAlt/>
                              </span>
                            </Link>{" "}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}


          <Modal show={editModel} onHide={OnHideEditModel}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">Update Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label htmlFor="fname">Menu Name :</Form.Label>
                  <Form.Control
                    id="MenuNameToEdit"
                    name="MenuNameToEdit"
                    placeholder="Menu Name"
                    type="text"
                    value={nameToEdit}
                    onChange={(e) => handleInputs(e)}
                  />
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={OnHideEditModel}>
                Close
              </Button>
              <Button variant="primary" onClick={HandleUpdate}>
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={addModel} onHide={OnHideAddModel}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">Add New Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label htmlFor="fname">Menu Name :</Form.Label>
                  <Form.Control
                    id="MenuNameToAdd"
                    name="MenuNameToAdd"
                    placeholder="Menu Name"
                    type="text"
                    value={nameToAdd}
                    onChange={(e) => handleInputsToADD(e)}
                  />
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={OnHideAddModel}>
                Close
              </Button>
              <Button variant="primary" onClick={HandleInsert}>
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    </Col>
  </Row>
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
</div>;
}

export default MenusList;
