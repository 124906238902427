import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Variables } from "../../Variables1/Variables";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";

function CollectionsList() {
  const [permissions, setPermissions] = useState(
    Util.decrypt(localStorage.getItem("UserPermissions"))
  );
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [collections, setCollections] = useState([]);
  const [publishModel, setPublishModel] = useState(0);
  const [editModel, setEditModel] = useState(0);
  const [addModel, setAddModel] = useState(false);
  
  const [idToEdit, setIdToEdit] = useState(0);
  const [nameToEdit, setNameToEdit] = useState("");

  const [nameToAdd, setNameToAdd] = useState("");

  const [search, SetSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Get Collections list
  const GetCollections = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Collection/GetCollections",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setCollections(result);
      setFilteredData(result);
    }
    setLoader(false);
  };

  // handle Search
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    SetSearch(value);
    result = collections.filter((data) => {
      return data.CollectionName.toLowerCase().search(value) != -1;
    });
    setFilteredData(result);
  };

  // Publish - Unpublish collection
  const PublishUnpublishCollection = async (collecId) => {
    let response = await fetch(
      Variables.API_URL + "Collection/PublishUnpublishCollection/" + collecId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPublishModel(0);
      GetCollections();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const SetUpdate = (colId, colName) => {
    setIdToEdit(colId);
    setNameToEdit(colName);
    setEditModel(colId);
  };

  function OnHideEditModel() {
    setIdToEdit(0);
    setNameToEdit("");
    setEditModel(0);
    return false;
  }

  function OnHideAddModel() {
    setNameToAdd("");
    setAddModel(false);
    return false;
  }

  // handle  Inputs
  const handleInputs = (e, id, namex) => {
    const { name, value } = e.target;

    var element;
    if (value === "" || value == null) {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setNameToEdit(value);
  };

  // handle  Inputs TO Add
  const handleInputsToADD = (e, id, namex) => {
    const { name, value } = e.target;

    var element;
    if (value === "" || value == null) {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setNameToAdd(value);
  };

  // Is Valid To Add
  function IsValidToAdd() {
    var hasError = false;

    if (nameToAdd === "" || nameToAdd === null) {
      var element = document.getElementById("CollectionNameToAdd");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (hasError) {
      return false;
    } else {
      return true;
    }
  }

  // Is Valid To Edit
  function IsValidToEdit() {
    var hasError = false;

    if (nameToEdit === "" || nameToEdit === null) {
      var element = document.getElementById("CollectionNameToEdit");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (idToEdit === 0 || idToEdit === null) {
      hasError = true;
    }
    if (hasError) {
      return false;
    } else {
      return true;
    }
  }

  // Handle Insert
  const HandleUpdate = async () => {
    var valid = IsValidToEdit();
    if (valid) {
      let response = await fetch(
        Variables.API_URL + "Collection/UpdateCollection",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CollectionId: idToEdit,
            Name: nameToEdit,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        GetCollections();
        setIdToEdit(0);
        setNameToEdit("");
        setEditModel(false);
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please select name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Insert
  const HandleInsert = async (e) => {
    e.preventDefault();

    var valid = IsValidToAdd();
    if (valid == true) {
      let response = await fetch(
        Variables.API_URL + "Collection/AddNewCollection",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Name: nameToAdd,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddModel(false);
        GetCollections();
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please Select Name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
      if(permissions.ViewCollections === false){
          navigate("/")
      }
    GetCollections();
  }, []);

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Collections List</h4>
              </div>
              {permissions.ManageCollections === false ? (
                ""
              ) : (
                <Button variant="primary" onClick={() => setAddModel(true)}>
                  Add New Collection
                </Button>
              )}
            </Card.Header>
            <Card.Body className="px-0">
              <Form.Control
                id="Search"
                name="Search"
                placeholder="Search"
                type="text"
                className="allignCenter"
                onChange={handleSearch}
              />
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th></th>
                        <th>Name</th>
                        <th>Publish</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((tdata, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{tdata.CollectionName}</td>
                          <td>
                            <span
                              className={
                                tdata.IsPublished === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {tdata.IsPublished === true
                                ? "Published"
                                : "Unpublished"}
                            </span>
                          </td>
                          <td>
                            {permissions.ManageCollections === false ? (
                              ""
                            ) : (
                              <div className="flex align-items-center list-user-action">
                                <Link
                                  className="btn btn-sm btn-icon btn-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Publish"
                                  to="#"
                                  onClick={() =>
                                    setPublishModel(tdata.CollectionId)
                                  }
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="32"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M11.8628 14.2026V16.4236"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </Link>{" "}
                                <Modal
                                  show={tdata.CollectionId === publishModel}
                                  onHide={() => setPublishModel(0)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title as="h5">
                                      {tdata.IsPublished
                                        ? "Unpublished Collection"
                                        : "Published Collection"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to{" "}
                                    {tdata.IsPublished
                                      ? "Unpublish : "
                                      : "Publish : "}
                                    <b>{tdata.CollectionName}</b>?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() => setPublishModel(0)}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        PublishUnpublishCollection(
                                          tdata.CollectionId
                                        )
                                      }
                                    >
                                      Save changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                <Link
                                  className="btn btn-sm btn-icon btn-success"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"
                                  onClick={() =>
                                    SetUpdate(
                                      tdata.CollectionId,
                                      tdata.CollectionName
                                    )
                                  }
                                  to={"#"}
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M15.1655 4.60254L19.7315 9.16854"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </Link>{" "}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <Modal show={editModel} onHide={OnHideEditModel}>
                <Modal.Header closeButton>
                  <Modal.Title as="h5">Update Collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Collection Name :</Form.Label>
                      <Form.Control
                        id="CollectionNameToEdit"
                        name="CollectionNameToEdit"
                        placeholder="About"
                        placeholder="Collection Name"
                        type="text"
                        value={nameToEdit}
                        onChange={(e) => handleInputs(e)}
                      />
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={OnHideEditModel}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={HandleUpdate}>
                    Save changes
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={addModel} onHide={OnHideAddModel}>
                <Modal.Header closeButton>
                  <Modal.Title as="h5">Add New Collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Collection Name :</Form.Label>
                      <Form.Control
                        id="CollectionNameToAdd"
                        name="CollectionNameToAdd"
                        placeholder="About"
                        placeholder="Collection Name"
                        type="text"
                        value={nameToAdd}
                        onChange={(e) => handleInputsToADD(e)}
                      />
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={OnHideAddModel}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={HandleInsert}>
                    Save changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CollectionsList;
