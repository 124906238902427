import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Variables } from "../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import CustomSnipper from "../../components/customSnipper";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Dropzone from "react-dropzone";
import moment from "moment";

import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
  Nav,
  FormCheck,
} from "react-bootstrap";
import { Card } from "react-bootstrap";

function ManageProduct() {
  const RouteProductId = useParams();
  const [productId, setProductId] = useState(
    Number(RouteProductId.RouteProductId) || 0
  );

  const RouteResto = useParams();
  const [restaurantId, setRestaurantId] = useState(
    Number(RouteResto.RouteResto) || 0
  );

  const navigate = useNavigate();

  const [restaurantAssignedCountries, setRestaurantAssignedCountries] =
    useState([]);
  const [assignedMenuSections, setAssignedMenuSections] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [productCountries, setProductCountries] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState([]);
  const [unrequiredIngredients, setUnrequiredIngredients] = useState([]);
  const [productDetails, setProductsDetails] = useState({
    RestaurantProductId: 0,
    RestaurantId: restaurantId,
    ProductName: "",
    Description: "",
    MenuSectionId: 0,
  });
  const MultiSeletecRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [productImage, setProductImage] = useState("");
  const [productImageToDb, setProductImageToDb] = useState("");
  const [productImagePath, setProductImagePath] = useState("");
  const [newProductId, setNewProductId] = useState(0);

  const [addOns, setAddOns] = useState([]);
  const [addonsCountries, setAddOnsCountries] = useState([]);

  //Get restaurant Assigned Countries
  const GerRestaurantAssignedCountries = async () => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/GetRestaurantCountries/" + restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRestaurantAssignedCountries(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Get restaurant Assigned menu sections
  const GerRestaurantAssignedMenuSections = async () => {
    let response = await fetch(
      Variables.API_URL +
        "RestaurantMenuSection/GetRestaurantMenuSections/" +
        restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setAssignedMenuSections(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Get Ingredients
  const GerIngredients = async () => {
    let response = await fetch(
      Variables.API_URL + "Ingredient/GetAllIngredients",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setIngredients(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let exist = false;

  // handle select Countries
  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
    var array = [];
    var existingCountries = [...productCountries];
    var counter = 0;

    productCountries.forEach((element) => {
      var exist = e.some((x) => x.CountryId == element.CountryId);
      if (exist === false) {
        existingCountries.splice(counter, 1);
      }
      counter = counter + 1;
    });
    var count = 0;

    e.forEach((element) => {
      var exist = existingCountries.some(
        (x) => x.CountryId === element.CountryId
      );
      if (exist === false) {
        existingCountries.push({
          RestaurantProductCountryId: 0,
          ProductId: productId,
          CountryId: element.CountryId,
          Price: 0,
          HasSalePrice: false,
          SalePrice: 0,
          SalePriceEndDate: moment().format("YYYY-MM-DD hh:mm:ss"),
        });
        count = count + 1;
      }
    });

    if (e.length) {
      setProductCountries(existingCountries);
    } else {
      setProductCountries([]);
    }

    var indexsToDelete = [];
    if (e.length > 0) {
      var counter = 0;
      var addOnsArry = [...addonsCountries];

      e.forEach((element) => {
        addOnsArry.forEach((addon) => {
          var exist = e.some((x) => x.CountryId === addon.CountryId);
          if (exist === false) {
            indexsToDelete.push(counter);
          }
          counter++;
        });
      });

      var countIndex = indexsToDelete.length - 1;
      indexsToDelete.forEach((index) => {
        addOnsArry.splice(indexsToDelete[countIndex], 1);
        countIndex--;
      });

      e.forEach((country) => {
        addOns.forEach((addon) => {
          var exist = addOnsArry.some(
            (x) =>
              x.CountryId == country.CountryId &&
              x.IngredientId == addon.IngredientId
          );
          if (exist === false) {
            addOnsArry.push({
              RestaurantProductAddonsId: 0,
              ProductId: productId,
              IngredientId: addon.IngredientId,
              IngredientName: addon.IngredientName,
              CountryId: country.CountryId,
              ExtraPrice: "",
            });
          }
        });
      });

      setAddOnsCountries(addOnsArry);
    } else {
      setAddOnsCountries([]);
    }

    if (e.length > 0) {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.remove("required");
    } else {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.add("required");
    }
  };

  // handle Select Addons
  const handleSelectAddOns = (e) => {
    var array = [];
    var arrayCountries = [...addonsCountries];
    e.forEach((element) => {
      array.push({
        ProductId: productId,
        IngredientId: element.IngredientId,
        IngredientName: element.IngredientName,
      });

      selectedCountries.forEach((country) => {
        var exist = addonsCountries.some(
          (x) =>
            x.CountryId == element.CountryId ||
            x.IngredientId == element.IngredientId
        );
        if (exist === false) {
          arrayCountries.push({
            RestaurantProductAddonsId: 0,
            ProductId: productId,
            IngredientId: element.IngredientId,
            IngredientName: element.IngredientName,
            CountryId: country.CountryId,
            ExtraPrice: "",
          });
        }
      });
    });

    var counter = 0;
    var indexsToDelete = [];
    e.forEach((element) => {
      addonsCountries.forEach((addon) => {
        var exist = e.some((x) => x.IngredientId === addon.IngredientId);
        if (exist === false) {
          indexsToDelete.push(counter);
        }
        counter++;
      });
    });

    var countIndex = indexsToDelete.length - 1;
    indexsToDelete.forEach((index) => {
      arrayCountries.splice(indexsToDelete[countIndex], 1);
      countIndex--;
    });

    setAddOns(array);
    setAddOnsCountries(arrayCountries);
  };

  // Handle Has SalePrice
  const HandleHasSalePrice = (e, i) => {
    const { name, value } = e.target;

    var array = [...productCountries];
    var indexToUpdate = array[i];

    var newValue = false;
    if (value == "off" || value == "false") {
      newValue = true;
    }
    if (value == "on" || value == "true") {
      newValue = false;
    }
    indexToUpdate["HasSalePrice"] = newValue;
    setProductCountries(array);
  };

  // handle Product prices Change
  const handleProductPriceChange = (e, i) => {
    const { name, value } = e.target;
    var array = [...productCountries];
    var indexToUpdate = array[i];
    indexToUpdate[name] = value;

    if (value === "" || value === null || value == 0) {
      var element = document.getElementById(name + i);
      element.classList.add("is-invalid");
    } else {
      var element = document.getElementById(name + i);
      element.classList.remove("is-invalid");
    }
    setProductCountries(array);
  };

  // handle Select Ingredients
  const handleSelectIngredients = (e) => {
    MultiSeletecRef.current.resetSelectedValues();
    setSelectedIngredient(e);

    if (e.length == 0) {
      setUnrequiredIngredients([]);
    } else {
      e.forEach((element) => {
        var exist = unrequiredIngredients.some(
          (x) => x.IngredientId == element.IngredientId
        );
        if (exist) {
          var index = unrequiredIngredients.findIndex(function (o) {
            return o.IngredientId === element.IngredientId;
          });
          console.log("index", index);
          if (index !== -1) unrequiredIngredients.splice(index, 1);

          console.log("include");
        } else {
          console.log("not include");
        }
      });
    }

    handleSelectUnrequiredIngredients(unrequiredIngredients);

    if (e.length > 0) {
      var element = document.getElementById("Ingredients").childNodes[0];
      element.classList.remove("required");
    } else {
      var element = document.getElementById("Ingredients").childNodes[0];
      element.classList.add("required");
    }
  };

  // handle Select Unrequired Ingredients
  const handleSelectUnrequiredIngredients = (e) => {
    setUnrequiredIngredients(e);
  };

  // Handle Input product info
  const HandleInputProductInfo = (e) => {
    const { name, value } = e.target;

    setProductsDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value === "" || value === null || value == 0) {
      var element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      var element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
  };

  // Check input validity
  function IsValid() {
    var hasError = false;
    if (
      productDetails.ProductName === "" ||
      productDetails.ProductName === null
    ) {
      var element = document.getElementById("ProductName");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    if (
      productDetails.Description === "" ||
      productDetails.Description === null
    ) {
      var element = document.getElementById("Description");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    if (
      productDetails.MenuSectionId === 0 ||
      productDetails.MenuSectionId === "0"
    ) {
      var element = document.getElementById("MenuSectionId");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }
    if (selectedCountries.length == 0) {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.add("required");
      element.focus();
      hasError = true;
    }
    // if (selectedIngredient.length == 0) {
    //   var element = document.getElementById("Ingredients").childNodes[0];
    //   element.classList.add("required");
    //   element.focus();
    //   hasError = true;
    // }

    var counter = 0;
    productCountries.forEach((element) => {
      if (
        element.Price === "" ||
        element.Price === null ||
        element.Price == 0
      ) {
        var element = document.getElementById("Price" + counter);
        element.classList.add("is-invalid");
        element.focus();
        hasError = true;
      }
      if (element.HasSalePrice) {
        if (
          element.SalePrice === "" ||
          element.SalePrice === null ||
          element.SalePrice == 0
        ) {
          var element = document.getElementById("SalePrice" + counter);
          element.classList.add("is-invalid");
          element.focus();
          hasError = true;
        }
        if (
          element.SalePriceEndDate === "" ||
          element.SalePriceEndDate === null
        ) {
          var element = document.getElementById("SalePriceEndDate" + counter);
          element.classList.add("is-invalid");
          element.focus();
          hasError = true;
        }
      }
      counter++;
    });

    addonsCountries.forEach((element) => {
      if (element.ExtraPrice === "" || element.ExtraPrice === null) {
        var elem = document.getElementById(
          "AddOnsCountryIngredient-Country" +
            element.CountryId +
            "-Ingredient" +
            element.IngredientId
        );
        elem.classList.add("is-invalid");
        elem.focus();
        hasError = true;
      } else {
        var elem = document.getElementById(
          "AddOnsCountryIngredient-Country" +
            element.CountryId +
            "-Ingredient" +
            element.IngredientId
        );
        elem.classList.remove("is-invalid");
      }
    });

    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // Insert Product
  const InsertProduct = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "RestaurantProduct/AddRestaurantProduct",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantId: restaurantId,
          productInfo: productDetails,
          Ingredients: selectedIngredient,
          UnrequiredIngredients: unrequiredIngredients,
          Countries: productCountries,
          AddOns: addonsCountries,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const formData2 = new FormData();
      formData2.append("Image", productImageToDb);
      formData2.append("RestaurantId", restaurantId);
      formData2.append("FolderPath", "Products");

      const resImage = await axios.post(
        Variables.API_URL + "Restaurant/UploadRestoMedia",
        formData2
      );
      console.log("resImage.data", resImage.data);
      if (resImage.data !== "") {
        let responsePathImage = await fetch(
          Variables.API_URL + "RestaurantProduct/UpdateProductImagePath",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              RestaurantId: restaurantId,
              ProductName: productDetails.ProductName,
              Path: resImage.data,
            }),
          }
        );
        let resultInsertImagePathMain = await responsePathImage.json();
      }

      setTimeout(() => navigate("/Restaurant/" + restaurantId), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  // Get Product Details
  const GetProductDetails = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestaurantProduct/GetRestaurantProductDetails/Restaurant/" +
        restaurantId +
        "/Product/" +
        productId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    console.log("result", result.ProductImagePath);
    if (response.status === 200) {
      setProductsDetails(result.productInfo);
      setSelectedIngredient(result.Ingredients);
      setUnrequiredIngredients(result.UnrequiredIngredients);
      setSelectedCountries(result.CountriesDdl);
      setProductCountries(result.Countries);
      setProductImagePath(result.ProductImagePath);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  // Update Product
  const UpdateProduct = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "RestaurantProduct/UpdateRestaurantProduct",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantId: restaurantId,
          ProductId: productId,
          productInfo: productDetails,
          Ingredients: selectedIngredient,
          UnrequiredIngredients: unrequiredIngredients,
          Countries: productCountries,
          AddOns: addonsCountries,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      if (productImageToDb !== "") {
        const formData2 = new FormData();
        formData2.append("Image", productImageToDb);
        formData2.append("RestaurantId", restaurantId);
        formData2.append("FolderPath", "Products");

        const resImage = await axios.post(
          Variables.API_URL + "Restaurant/UploadRestoMedia",
          formData2
        );
        console.log("resImage.data", resImage.data);
        if (resImage.data !== "") {
          let responsePathImage = await fetch(
            Variables.API_URL + "RestaurantProduct/UpdateProductImagePath",
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                RestaurantId: restaurantId,
                ProductName: productDetails.ProductName,
                Path: resImage.data,
              }),
            }
          );
          let resultInsertImagePathMain = await responsePathImage.json();
        }
      }
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  // Handle Submit
  const HandleSubmit = () => {
    var valid = IsValid();
    if (valid) {
      if (productId == 0) {
        InsertProduct();
      } else {
        UpdateProduct();
      }
    } else {
      toast.error("Please Fill All Required Fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Set main image
  const SetProductImage = (e) => {
    var x = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      if (
        e.target.result.indexOf("png") >= 0 ||
        e.target.result.indexOf("jpeg") >= 0 ||
        e.target.result.indexOf("jpg") >= 0
      ) {
        setProductImageToDb(x);
        setProductImage(e.target.result);
      } else {
        toast.error("File type error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // handle AddOns Price
  const handleAddOnsPrice = (e, i, countryId, ingredientId) => {
    const { name, value } = e.target;

    if (value === "" || value === null) {
      var element = document.getElementById(
        "AddOnsCountryIngredient-Country" +
          countryId +
          "-Ingredient" +
          ingredientId
      );
      element.classList.add("is-invalid");
    } else {
      var element = document.getElementById(
        "AddOnsCountryIngredient-Country" +
          countryId +
          "-Ingredient" +
          ingredientId
      );
      element.classList.remove("is-invalid");
    }

    var counter = 0;
    var i = 0;
    addonsCountries.forEach((element) => {
      if (
        element.CountryId === countryId &&
        element.IngredientId === ingredientId
      ) {
        i = counter;
      }
      counter++;
    });

    var array = [...addonsCountries];
    var addonToUpdate = array[i];
    delete array[i];
    addonToUpdate["ExtraPrice"] = value;
    array[i] = addonToUpdate;

    var addonCountry = array.filter((x) => x.CountryId == countryId);
    var addonIngredient = addonCountry.filter(
      (x) => x.IngredientId == ingredientId
    );

    setAddOnsCountries(array);
  };

  // Get Product Addons
  const GetProductAddons = async () => {
    let response = await fetch(
      Variables.API_URL + "RestaurantProduct/GetProductAddOns/" + productId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setAddOns(result.Addons);
      setAddOnsCountries(result.CountryAddons);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (restaurantId !== 0) {
      setTimeout(() => GerIngredients(), 1500);
      
      GerRestaurantAssignedCountries();
      GerRestaurantAssignedMenuSections();
      
      if (productId !== 0) {
        GetProductDetails();
        GetProductAddons();
      }
    }
  }, [restaurantId, productId]);
  console.log("ingredients",ingredients)
  console.log("addonsCountries",addonsCountries)

  return (
    <div>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Product</h4>
              </div>
              <Button variant="secondary" onClick={() => navigate("/Restaurant/"+restaurantId)}>
                  Cancel
                </Button>
            </Card.Header>
            {loader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="new-user-info">
                  <Row>
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">Product Name:</Form.Label>
                      <Form.Control
                        id="ProductName"
                        name="ProductName"
                        placeholder="Product Name"
                        type="text"
                        value={productDetails.ProductName}
                        onChange={HandleInputProductInfo}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">Menu Section:</Form.Label>
                      <select
                        className="form-control"
                        name="MenuSectionId"
                        id="MenuSectionId"
                        value={productDetails.MenuSectionId}
                        onChange={HandleInputProductInfo}
                      >
                        <option key={0} value={0}>
                          -- Select Menu Section --
                        </option>
                        {assignedMenuSections.map((x) => {
                          return (
                            <option
                              key={x.MenuSectionId}
                              value={x.MenuSectionId}
                            >
                              {x.MenuSectionName}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Group>

                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Description:</Form.Label>
                      <textarea
                        className="form-control"
                        id="Description"
                        name="Description"
                        placeholder="Description"
                        type="textarea"
                        value={productDetails.Description}
                        onChange={HandleInputProductInfo}
                      ></textarea>
                    </Form.Group>

                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Ingredients:</Form.Label>
                      <Multiselect
                        options={ingredients} // Options to display in the dropdown
                        selectedValues={selectedIngredient} // Preselected value to persist in dropdown
                        displayValue="IngredientName" // Property name to display in the dropdown options
                        placeholder="Select Ingredients"
                        id="Ingredients"
                        name="Ingredients"
                        onSelect={handleSelectIngredients}
                        onRemove={handleSelectIngredients}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">
                        Unrequired Ingredients:
                      </Form.Label>
                      <Multiselect
                        options={selectedIngredient} // Options to display in the dropdown
                        selectedValues={unrequiredIngredients} // Preselected value to persist in dropdown
                        displayValue="IngredientName" // Property name to display in the dropdown options
                        placeholder="Unrequired Ingredients"
                        id="UnrequiredIngredients"
                        name="UnrequiredIngredients"
                        onSelect={handleSelectUnrequiredIngredients}
                        onRemove={handleSelectUnrequiredIngredients}
                        ref={MultiSeletecRef}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">Countries</Form.Label>
                      <Multiselect
                        options={restaurantAssignedCountries} // Options to display in the dropdown
                        selectedValues={selectedCountries} // Preselected value to persist in dropdown
                        displayValue="CountryName" // Property name to display in the dropdown options
                        placeholder="Select Countries"
                        id="Countries"
                        name="Countries"
                        onSelect={handleSelectCountry}
                        onRemove={handleSelectCountry}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">Add Ons</Form.Label>
                      <Multiselect
                        options={ingredients} // Options to display in the dropdown
                        selectedValues={addOns} // Preselected value to persist in dropdown
                        displayValue="IngredientName" // Property name to display in the dropdown options
                        placeholder="Add-Ons"
                        id="AddOns"
                        name="AddOns"
                        onSelect={handleSelectAddOns}
                        onRemove={handleSelectAddOns}
                      />
                    </Form.Group>
                  </Row>

                  <br />
                  <>
                    <Row>
                      <Col className="Col-Text-Center">
                        <h5 className="mb-3">Product Image</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Form.Group className="col-md-12 form-group">
                        <div className="mb-3">
                          <Form.Label className="" htmlFor="customFile">
                            Image
                          </Form.Label>
                          <Form.Control
                            id="Image"
                            name="Image"
                            placeholder="Image"
                            type="file"
                            accept="image/*"
                            onChange={SetProductImage}
                          />
                        </div>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col className="allignCenter">
                        {productImage !== "" ? (
                          <div>
                            <img
                              src={productImage}
                              className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                              alt="Boat on Calm Water"
                            />
                          </div>
                        ) : productImagePath !== "" &&
                          productImagePath !== null ? (
                          <div >
                            <img
                              src={Variables.imagePath + productImagePath}
                              className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                              alt="Boat on Calm Water"
                            />
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>

                  {productCountries.length > 0 ? (
                    <>
                      <br />
                      <Row>
                        <Col className="Col-Text-Center">
                          <h5 className="mb-3">Product Price</h5>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {productCountries.map((data, i) => (
                    <>
                      <Row key={i} className="mt-3">
                        <Form.Group className="col-md-2 form-group">
                          <Form.Label htmlFor="fname">Country:</Form.Label>
                          <Form.Control
                            id={"ProductCountry" + i}
                            name={"ProductCountry" + i}
                            placeholder="Country"
                            type="text"
                            disabled={true}
                            value={
                              selectedCountries.find((element) => {
                                return element.CountryId === data.CountryId;
                              }).CountryName
                            }
                            onChange={(e) => handleProductPriceChange(e, i)}
                          />
                        </Form.Group>

                        <Form.Group className="col-md-2 form-group">
                          <Form.Label htmlFor="fname">Price :</Form.Label>
                          <Form.Control
                            id={"Price" + i}
                            name={"Price"}
                            placeholder="Regular Price"
                            type="number"
                            value={data.Price}
                            onChange={(e) => handleProductPriceChange(e, i)}
                          />
                        </Form.Group>

                        <Form.Group className="col-md-2 form-group">
                          <Form.Label htmlFor="fname">Sale Price :</Form.Label>
                          <Form.Control
                            id={"SalePrice" + i}
                            name={"SalePrice"}
                            placeholder="Sale Price"
                            type="number"
                            disabled={
                              data.HasSalePrice === false ? true : false
                            }
                            value={
                              data.HasSalePrice === false ? "" : data.SalePrice
                            }
                            onChange={(e) => handleProductPriceChange(e, i)}
                          />
                        </Form.Group>

                        <Form.Group className="col-md-3 form-group">
                          <Form.Label htmlFor="fname">
                            End Sale Price :
                          </Form.Label>
                          <Form.Control
                            id={"SalePriceEndDate" + i}
                            name={"SalePriceEndDate"}
                            placeholder="To .... YYYY-MM-DD"
                            type="datetime-local"
                            disabled={
                              data.HasSalePrice === false ? true : false
                            }
                            value={
                              data.HasSalePrice === false
                                ? ""
                                : data.SalePriceEndDate
                            }
                            onChange={(e) => handleProductPriceChange(e, i)}
                          />
                        </Form.Group>
                        <Form.Group className="col-md-3 form-group">
                          <Form.Label htmlFor="fname">.</Form.Label>
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              name="HasSalePrice"
                              id={"HasSalePrice"}
                              value={data.HasSalePrice}
                              checked={data.HasSalePrice}
                              onChange={(e) => HandleHasSalePrice(e, i)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Has Sale Sale
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                    </>
                  ))}

                  {selectedCountries.length > 0 && addonsCountries.length > 0 ? (
                    <>
                      <br />
                      <Row>
                        <Col className="Col-Text-Center">
                          <h5 className="mb-3">Add Ons Extra Price</h5>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        {selectedCountries.map((country, index) => (
                          <Col lg="4" md="6" key={index}>
                            <Row>
                              <h6>
                                {
                                  selectedCountries.find((element) => {
                                    return (
                                      element.CountryId === country.CountryId
                                    );
                                  }).CountryName
                                }
                              </h6>
                              {addonsCountries
                                .filter(
                                  (country1) =>
                                    country1.CountryId === country.CountryId
                                )
                                .map((data, i) => (
                                  <>
                                  {ingredients.length > 0 ? <Row key={i} className="mt-2">
                                      <>
                                        <Form.Group className="col-md-6 form-group">
                                          <Form.Control
                                            id={"AddOnsCountryIngredient" + i}
                                            name={"AddOnsCountryIngredient" + i}
                                            placeholder="AddOns"
                                            type="text"
                                            disabled={true}
                                            value={
                                              // ingredients.find((element) => {
                                              //   return (
                                              //     element.IngredientId ===
                                              //     data.IngredientId
                                              //   );
                                              // }).IngredientName
                                              ""
                                            }
                                          />
                                        </Form.Group>

                                        <Form.Group className="col-md-6 form-group">
                                          <Form.Control
                                            id={
                                              "AddOnsCountryIngredient-Country" +
                                              data.CountryId +
                                              "-Ingredient" +
                                              data.IngredientId
                                            }
                                            name={"AddOnsCountryIngredient" + i}
                                            placeholder="Extra Price"
                                            type="NUMBER"
                                            value={data.ExtraPrice}
                                            onChange={(e) =>
                                              handleAddOnsPrice(
                                                e,
                                                index,
                                                data.CountryId,
                                                data.IngredientId
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </>
                                    </Row> : ""}
                                    
                                  </>
                                ))}
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  
                </div>

                <div className="float-right">
                  <Button
                    type="button"
                    variant="btn btn-primary"
                    onClick={HandleSubmit}
                  >
                    Submit
                  </Button>{" "}
                  <Button
                    type="button"
                    variant="btn btn-secondary"
                    onClick={() => navigate("/Restaurant/" + restaurantId)}
                  >
                    Cancel
                  </Button>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ManageProduct;
