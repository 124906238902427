import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form, Card } from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../Variables1/Variables";
import Multiselect from "multiselect-react-dropdown";
import Util from "../../Classes/Util";

function Countries() {
  const [countries, setCountries] = useState([]);
  const [assignedCountries, setAssignedCountries] = useState([]);
  const [loader, setLoader] = useState(false);
  const [permissions, setPermissions] = useState(
    Util.decrypt(localStorage.getItem("UserPermissions"))
  );

  //Get all countries details
  const GetAllCountries = async () => {
    let response = await fetch(Variables.API_URL + "Country/GetAllCountries", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setCountries(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Get Assigned countries
  const GetAssignedCountries = async () => {
    let response = await fetch(
      Variables.API_URL + "Country/GetAssignedCountries",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setAssignedCountries(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle select Countries
  const handleSelectCountries = (e) => {
    setAssignedCountries(e);
    if (e.length > 0) {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.remove("required");
    }
  };

  // handle remove Countries
  const handleRemoveCountries = (e) => {
    setAssignedCountries(e);
    if (e.length == 0) {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.add("required");
    }
  };

  //Assigne countries
  const AssigneCountries = async () => {
    let response = await fetch(Variables.API_URL + "Country/AssigneCountries", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(assignedCountries),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    setLoader(true);
    GetAllCountries();
    GetAssignedCountries();
    setLoader(false);
  }, []);
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Countries</h4>
              </div>
              {permissions.ManageCountries === false ? (
                ""
              ) : (
                <Button variant="primary" onClick={AssigneCountries}>
                  Submit
                </Button>
              )}
            </Card.Header>
            {loader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                        <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="fname">Assgned Countries:</Form.Label>
                        <Multiselect
                        options={countries} // Options to display in the dropdown
                        selectedValues={assignedCountries} // Preselected value to persist in dropdown
                        displayValue="CountryName" // Property name to display in the dropdown options
                        placeholder="Select Countries"
                        id="Countries"
                        name="Countries"
                        onSelect={handleSelectCountries}
                        onRemove={handleRemoveCountries}
                      />
                      </Form.Group>
                    </div>
                  </form>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Countries;
