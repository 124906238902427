import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../Variables1/Variables";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
  Nav,
  FormCheck,
  Card,
} from "react-bootstrap";

function ManageRestaurantUserRole() {

  const RouteRestaurantRoleId = useParams();
  const [roleId, setRoleId] = useState(Number(RouteRestaurantRoleId.RouteRestaurantRoleId) || 0);
  const [role, setRole] = useState({
    Name: "",
    ViewUsersList: false,
    ManageUser: false,
    ViewProductList: false,
    ManageProduct: false,
    ViewRestaurantDetails: false,
    ManageRestaurantDetails: false,
    ViewRestaurantMedia: false,
    ManageRestaurantMedia: false,
    ViewRestaurantMenuImages: false,
    ManageRestaurantMenuImages: false,
    ViewBranchesList: false,
    ManageBranch: false,
    ViewOrders: false,
    ManageOrder: false
  });

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  // Get Role
  const GetRole = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "RestoUserRole/GetRestoUserRoleById/" + roleId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRole(result);
    }
    setLoader(false);
  };

  // handle Name Input
  const handleNameInput = (e) => {
    const { name, value } = e.target;
    if (name === "Name") {
      console.log("name", value);
      var element;
      if (value === "") {
        element = document.getElementById(name);
        element.classList.add("is-invalid");
        element.focus();
      } else {
        element = document.getElementById(name);
        element.classList.remove("is-invalid");
        element.focus();
      }
    }
    setRole((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle Checkboxes Input
  const handleCheckboxesInput = (e) => {
    const { name, value } = e.target;
    if (role[name] === true) {
      setRole((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    } else {
      setRole((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
  };

  // Handle Insert
  const HandleInsert = async () => {
    let response = await fetch(Variables.API_URL1 + "RestoUserRole/AddNewRestoUserRole", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body: role }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => navigate("/RestaurantRole"), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Update
  const HandleUpdate = async () => {
    console.log("x", roleId);
    let response = await fetch(Variables.API_URL + "RestoUserRole/UpdateRestoUserRole", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body: role }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => navigate("/RestaurantRole"), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const Submit = () => {
    if(roleId === 0){
      HandleInsert()
    }
    else{
      HandleUpdate()
    }
  }

  useEffect(() => {
    if(roleId !== 0){
      GetRole()
    }
  },[])

  console.log(role)

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Role</h4>
              </div>
              <div>
                <Button variant="secondary" onClick={() => navigate("/RestaurantRole")}>
                  Cancel
                </Button>
                {"   "}
                <Button variant="primary" onClick={Submit}>
                  {loader === true ? <Spinner /> : "Submit"}
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="p-4">
                  <Row>
                    <Form.Group className="col-md-4 col-sm-12 form-group">
                      <Form.Label htmlFor="fname">Role Name :</Form.Label>
                      <Form.Control
                        id="Name"
                        name="Name"
                        placeholder="RoleName"
                        type="text"
                        value={role.Name}
                        onChange={handleNameInput}
                      />
                    </Form.Group>
                  </Row>
                  <br></br>
                  <Row>
                    <Col md="12">
                      {/* users */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Users
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewUsersList"
                              name="ViewUsersList"
                              checked={role.ViewUsersList}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Users List
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageUser"
                              name="ManageUser"
                              checked={role.ManageUser}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Users
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Product */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Products
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewProductList"
                              name="ViewProductList"
                              checked={role.ViewProductList}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Product List
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageProduct"
                              name="ManageProduct"
                              checked={role.ManageProduct}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Product
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Restaurant Details */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Restaurant Details
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewRestaurantDetails"
                              name="ViewRestaurantDetails"
                              checked={role.ViewRestaurantDetails}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Restaurant Details
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageRestaurantDetails"
                              name="ManageRestaurantDetails"
                              checked={role.ManageRestaurantDetails}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Restaurant Details
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Restaurant Media */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Restaurant Media
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewRestaurantMedia"
                              name="ViewRestaurantMedia"
                              checked={role.ViewRestaurantMedia}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Restaurant Media
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageRestaurantMedia"
                              name="ManageRestaurantMedia"
                              checked={role.ManageRestaurantMedia}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Restaurant Media
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Restaurant Media */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Restaurant Menu Images
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewRestaurantMenuImages"
                              name="ViewRestaurantMenuImages"
                              checked={role.ViewRestaurantMenuImages}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Restaurant Menu Images
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageRestaurantMenuImages"
                              name="ManageRestaurantMenuImages"
                              checked={role.ManageRestaurantMenuImages}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Restaurant Menu Images
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Branches */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Branches
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewBranchesList"
                              name="ViewBranchesList"
                              checked={role.ViewBranchesList}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Branches List
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageBranch"
                              name="ManageBranch"
                              checked={role.ManageBranch}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Branch
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Orders */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Orders
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewOrders"
                              name="ViewOrders"
                              checked={role.ViewOrders}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Orders
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageOrder"
                              name="ManageOrder"
                              checked={role.ManageOrder}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Order
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />
                    </Col>
                  </Row>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default ManageRestaurantUserRole