import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { Variables } from "../../../Variables1/Variables";
import CustomSnipper from "../../../components/customSnipper";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Card,
  Tab,
  TabContainer,
  Tabs,
  Nav,
  Spinner,
} from "react-bootstrap";
import { BiTrashAlt,BiLowVision,BiEditAlt } from "react-icons/bi";

function RestaurantProductsList(props) {
  const [loader, setLoader] = useState(false);
  const [publishModel, setPublishModel] = useState(0);
  const [deleteModel, setDeleteModel] = useState(0);
  const [products, setProducts] = useState([]);
  const navigate = new useNavigate();
  const [search, SetSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Get Products list
  const GetProducts = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestaurantProduct/GetRestaurantProductsList/" +
        props.restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setProducts(result);
      setFilteredData(result)
    }
    setLoader(false);
  };

  // Publish Unpublish Product
  const PublishUnpublishProduct = async (Id) => {
    let response = await fetch(
      Variables.API_URL +
        "RestaurantProduct/PublishUnpublishProduct/Restaurant/" +
        props.restaurantId +
        "/Product/" +
        Id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPublishModel(0);
      GetProducts();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Delete Product
  const DeleteProduct = async (Id) => {
    let response = await fetch(
      Variables.API_URL +
        "RestaurantProduct/DeleteProduct/Restaurant/" +
        props.restaurantId +
        "/Product/" +
        Id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDeleteModel(0);
      GetProducts();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // handle Search
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    SetSearch(value);
    result = products.filter((data) => {
      return data.ProductName.toLowerCase().search(value) !== -1 || data.MenuSectionName.toLowerCase().search(value) !== -1 ;
    });
    setFilteredData(result);
  };

  useEffect(() => {
    GetProducts();
  }, []);
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">products List</h4>
              </div>
              <Button variant="primary" onClick={() => navigate("/ManageProduct/Restaurant/"+props.restaurantId+"/Product/0")}>
                  Add New Product
                </Button>
            </Card.Header>
            <Card.Body className="px-0">
              <Form.Control
                id="Search"
                name="Search"
                placeholder="Search"
                type="text"
                className="allignCenter"
                onChange={handleSearch}
              />
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th className="text-center">#</th>
                        <th className="text-center">Product Name</th>
                        <th className="text-center">Menu Section</th>
                        <th className="text-center">Publish</th>
                        <th min-width="100px" className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((tdata, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <img
                              src={Variables.imagePath + tdata.ImagePath}
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              alt="Boat on Calm Water"
                            />
                          </td>
                          <td className="text-center">{tdata.ProductName}</td>
                          <td className="text-center">{tdata.MenuSectionName}</td>
                          <td className="text-center">
                            <span
                              className={
                                tdata.IsPublished === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {tdata.IsPublished === true
                                ? "Published"
                                : "Unpublished"}
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="flex align-items-center list-user-action">
                              <Link
                                className="btn btn-sm btn-icon btn-warning"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Lock"
                                to="#"
                                onClick={() => setPublishModel(tdata.ProductId)}
                              >
                                <span className="btn-inner">
                                  <BiLowVision/>
                                </span>
                              </Link>{" "}
                              <Modal
                                show={tdata.ProductId === publishModel}
                                onHide={() => setPublishModel(0)}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title as="h5">
                                    {tdata.IsPublished
                                      ? "Unpublished Product"
                                      : "Published Product"}
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Are you sure you want to{" "}
                                  {tdata.IsPublished
                                    ? "Unpublish : "
                                    : "Publish : "}
                                  <b>{tdata.ProductName}</b>?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setPublishModel(0)}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      PublishUnpublishProduct(tdata.ProductId)
                                    }
                                  >
                                    Save changes
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              <Link
                                className="btn btn-sm btn-icon btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Lock"
                                to="#"
                                onClick={() => setDeleteModel(tdata.ProductId)}
                              >
                                <span className="btn-inner">
                                  <BiTrashAlt/>
                                </span>
                              </Link>{" "}
                              <Modal
                                show={tdata.ProductId === deleteModel}
                                onHide={() => setDeleteModel(0)}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title as="h5">
                                    Delete Product
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Are you sure you want to Delete{" "}
                                  <b>{tdata.ProductName}</b>?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setDeleteModel(0)}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      DeleteProduct(tdata.ProductId)
                                    }
                                  >
                                    Save changes
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              <Link
                                className="btn btn-sm btn-icon btn-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to={
                                  "/ManageProduct/Restaurant/" +
                                  props.restaurantId +
                                  "/Product/" +
                                  tdata.ProductId
                                }
                              >
                                <span className="btn-inner">
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M15.1655 4.60254L19.7315 9.16854"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </Link>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default RestaurantProductsList;
