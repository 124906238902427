import React, { useState } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import validator from "validator";
import Util from "../../Classes/Util";

import { Variables } from "../../Variables1/Variables";

// img
import facebook from "../../assets/images/brands/fb.svg";
import google from "../../assets/images/brands/gm.svg";
import instagram from "../../assets/images/brands/im.svg";
import linkedin from "../../assets/images/brands/li.svg";
import auth1 from "../../assets/images/auth/01.png";
import logo from "../../assets/images/PickAppLogo/pickkLogoSmall.svg"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignIn = ({ setIsAuth }) => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);
  const [recaptchaVisible, SetRecaptchaVisible] = useState(false);
  const [isVerified, setisVerified] = useState(true);
  const [loader,setLoader] = useState(false);

  const navigate = useNavigate();

  //handle email change
  const handleEmail = (e) => {
    const { name, value } = e.target;
    if (validator.isEmail(value)) {
      setEmailFormatError(false);
    }
    if (value === "") {
      setEmailRequiredError(true);
      setEmailFormatError(false);
    } else {
      setEmailRequiredError(false);
    }
    setEmail(value);
  };

  //handle password change
  const handlePassword = (e) => {
    const { value } = e.target;
    if (value === "") {
      setPasswordRequiredError(true);
    } else {
      setPasswordRequiredError(false);
    }
    setPassword(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (email == null || email == "") {
      setEmailRequiredError(true);
      hasError = true;
    } else if (!validator.isEmail(email)) {
      setEmailFormatError(true);
      hasError = true;
    }
    if (password == null || password == "") {
      setPasswordRequiredError(true);
      hasError = true;
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  function onRecaptchaChange() {
    setisVerified(true);
  }

  //Login function
  const Login = async () => {
    setLoader(true);
    var isValid = IsValid();
    // case of recaptcha verified
    if (isVerified) {
      //case of valid input
      if (isValid === true) {
        //fetch
        let response = await fetch(Variables.API_URL + "User/UserLogin", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
        let result = await response.json();
        //case of success
        if (response.status === 200) {
          localStorage.setItem("UserEmail", Util.encrypt(result.Email)); // set email in localStorage
          localStorage.setItem(
            "UserPermissions",
            Util.encrypt(result.Permissions)
          ); // set permissions in localStorage
          localStorage.setItem(
            "UserLoginToken",
            Util.encrypt(result.LoginToken)
          ); // set Login Token in localStorage
          localStorage.setItem(
            "UserId",
            Util.encrypt(result.UserId)
          ); // set UserId in localStorage
          setIsAuth(true);
          setisVerified(true);
          setLoader(false);
          navigate("/");
        }
        // case of valid temp email
        else if (response.status === 403) {
          toast.error(result, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          // we redirect the user for reset password
          localStorage.setItem("paOd", Util.encrypt(password)); // set old pass in localStorage
          localStorage.setItem("AEMl", Util.encrypt(email)); // set email in localStorage
          setLoader(false);
          navigate("/ResetPassword");
        }

        //invalid email or password
        else {
          toast.error(result, {
            position: "top-right",

            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setisVerified(false);
          SetRecaptchaVisible(false);
          SetRecaptchaVisible(true);
          setLoader(false)
        }
      }
      setLoader(false);
    }
    // unverified recaptcha
    else {
      toast.error("Please resolve recaptcha", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false)
  };

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <Link
                      to="/dashboard"
                      className="navbar-brand d-flex align-items-center mb-3"
                    >
                      {/* <svg
                        width="30"
                        className="text-primary"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="-0.757324"
                          y="19.2427"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(-45 -0.757324 19.2427)"
                          fill="currentColor"
                        />
                        <rect
                          x="7.72803"
                          y="27.728"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(-45 7.72803 27.728)"
                          fill="currentColor"
                        />
                        <rect
                          x="10.5366"
                          y="16.3945"
                          width="16"
                          height="4"
                          rx="2"
                          transform="rotate(45 10.5366 16.3945)"
                          fill="currentColor"
                        />
                        <rect
                          x="10.5562"
                          y="-0.556152"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(45 10.5562 -0.556152)"
                          fill="currentColor"
                        />
                      </svg> */}
                      <Image src={logo} alt="Logo" />
                      <h4 className="logo-title ms-3">PickApp - Admin</h4>
                    </Link>
                    <h2 className="mb-2 text-center">Sign In</h2>
                    <p className="text-center">Login to stay connected.</p>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              aria-describedby="email"
                              placeholder=" "
                              value={email}
                              onChange={handleEmail}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password" className="">
                              Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              className=""
                              id="password"
                              aria-describedby="password"
                              placeholder=" "
                              value={password}
                              onChange={handlePassword}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-between">
                          <Form.Check className="form-check mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              Remember Me
                            </Form.Check.Label>
                          </Form.Check>
                          <Link to="/forgetPassword">Forgot Password?</Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={() => Login()}
                          className="mt-3"
                          type="button"
                          variant="primary"
                        >
                          {loader === true ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Sign In"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="sign-bg">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={auth1}
              className="Image-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SignIn;
