import React, { useState, useEffect, useCallback } from "react";
// import Card from "../../components/Card";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
  Nav,
  FormCheck,
  Card,
} from "react-bootstrap";
import Test2 from "../Map/test2";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

function Test() {
  const localizer = momentLocalizer(moment);
  
  const myEventsList = [
    { start: new Date(), end: new Date(), title: "special event", id: 1},
  ];
  const [newEvent,setNewEvent] = useState({start: new Date(), end: "", title: ""})
  const x = (e) => {
    console.log(e);
    console.log(Date().toLocaleString())
  }

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      console.log(newEvent)
      
    },
    []
  )

  return (
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Test</h4>
            </div>
          </Card.Header>
          <Card.Body className="px-0">
            <div className="container-fluid">
              <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                defaultDate={Date().toLocaleString()}
                style={{ height: 500 }}
                onDoubleClickEvent={(e) => x(e)}
                onSelectEvent={(e) => x(e)}     
                selectable           
                onSelectSlot={handleSelectSlot}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Test;
