//router
import IndexRouters from "./router/index";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";

import { Button } from "react-bootstrap";
//header
import Header from "./components/partials/dashboard/HeaderStyle/header";
//subheader
import SubHeader from "./components/partials/dashboard/HeaderStyle/sub-header";
import Sidebar from "./components/partials/dashboard/SidebarStyle/sidebar";
import Footer from "./components/partials/dashboard/FooterStyle/footer";
import DefaultRouter from "./router/default-router";
import Loader from "./components/Loader";
import PublicRoutes from "./Variables1/PublicRoutes";

// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "./store/setting/setting";
import { connect } from "react-redux";
import Util from "./Classes/Util";
import { Variables } from "./Variables1/Variables";

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

function App() {
  const [isAuth, setIsAuth] = useState(null);

  // Check Login Token
  const CheckLoginToken = async () => {
    let response = await fetch(Variables.API_URL + "User/checkUserLoginToken", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Email: Util.decrypt(localStorage.getItem("UserEmail")),
        Token: Util.decrypt(localStorage.getItem("UserLoginToken")),
      }),
    });
    let result = await response.json();
    if (response.status === 200) {
      setIsAuth(true);
    } else {
      console.log("xxx");
      setIsAuth(false);
    }
  };

  useEffect(() => {
    if ("UserEmail" in localStorage && "UserLoginToken" in localStorage) {
      CheckLoginToken();
    } else {
      setIsAuth(false);
    }
  }, []);
  return (
    <div className="App">
      {isAuth === true && (
        <>
          <Loader />
          <Sidebar/>
          <main className="main-content">
            <div className="position-relative">
              <Header setIsAuth={setIsAuth} />
              <SubHeader />
            </div>
            <div className="py-0 conatiner-fluid content-inner mt-n5">
              <DefaultRouter setIsAuth={setIsAuth} isAuth={isAuth} />
            </div>

            <Footer />
          </main>
        </>
      )}

      {isAuth === false && <PublicRoutes setIsAuth={setIsAuth} />}
    </div>
  );
}

export default App;
