import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../Variables1/Variables";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
  Nav,
  FormCheck,
  Card,
} from "react-bootstrap";
function ManageRole() {
  const RouteRoleId = useParams();
  const [roleId, setRoleId] = useState(Number(RouteRoleId.RouteRoleId) || 0);
  const [role, setRole] = useState({
    Name: "",
    ViewUsers: false,
    ManageUser: false,
    ViewCollections: false,
    ManageCollections: false,
    ViewMenus: false,
    ManageMenus: false,
    ViewIngredients: false,
    ManageIngredients: false,
    ViewCountries: false,
    ManageCountries: false,
    ViewRestaurants: false,
    ManageRestaurant: false,
    ViewBranches: false,
    ManageBranch: false,
    ViewOrders: false,
    ManageOrder: false,
    ViewProducts: false,
    ManageProduct: false,
    ViewMenuImages: false,
    ManageMenuImages: false,
    ViewMedia: false,
    ManageMedia: false,
  });

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  // Get Role
  const GetRole = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "UserRole/GetUserRoleById/" + roleId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setRole(result);
    }
    setLoader(false);
  };

  // handle Name Input
  const handleNameInput = (e) => {
    const { name, value } = e.target;
    if (name === "Name") {
      console.log("name", value);
      var element;
      if (value === "") {
        element = document.getElementById(name);
        element.classList.add("is-invalid");
        element.focus();
      } else {
        element = document.getElementById(name);
        element.classList.remove("is-invalid");
        element.focus();
      }
    }
    setRole((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle Checkboxes Input
  const handleCheckboxesInput = (e) => {
    const { name, value } = e.target;
    if (role[name] === true) {
      setRole((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    } else {
      setRole((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
  };

  // Handle Insert
  const HandleInsert = async () => {
    let response = await fetch(Variables.API_URL1 + "UserRole/AddUserRole", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body: role }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => navigate("/Role"), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Update
  const HandleUpdate = async () => {
    console.log("x", roleId);
    let response = await fetch(Variables.API_URL + "UserRole/UpdateUserRole", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body: role }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => navigate("/Role"), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Submit
  const Submit = () => {
    if (roleId === 0) {
      HandleInsert();
    } else {
      HandleUpdate();
    }
  };

  useEffect(() => {
    if (roleId !== 0) {
      GetRole();
    }
  }, []);

  console.log("len", role);

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Role</h4>
              </div>
              <div>
                <Button variant="secondary" onClick={() => navigate("/Role")}>
                  Cancel
                </Button>
                {"   "}
                <Button variant="primary" onClick={Submit}>
                  {loader === true ? <Spinner /> : "Submit"}
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="p-4">
                  <Row>
                    <Form.Group className="col-md-4 col-sm-12 form-group">
                      <Form.Label htmlFor="fname">Role Name :</Form.Label>
                      <Form.Control
                        id="Name"
                        name="Name"
                        placeholder="RoleName"
                        type="text"
                        value={role.Name}
                        onChange={handleNameInput}
                      />
                    </Form.Group>
                  </Row>
                  <br></br>
                  <Row>
                    <Col md="12">
                      {/* users */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Users
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewUsers"
                              name="ViewUsers"
                              checked={role.ViewUsers}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Users
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageUser"
                              name="ManageUser"
                              checked={role.ManageUser}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Users
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Restaurant */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Restaurant
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <div className="col-md-8">
                          <Row>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ViewRestaurants"
                                  name="ViewRestaurants"
                                  checked={role.ViewRestaurants}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  View Restaurants
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ManageRestaurant"
                                  name="ManageRestaurant"
                                  checked={role.ManageRestaurant}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  Manage Restaurant
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ViewBranches"
                                  name="ViewBranches"
                                  checked={role.ViewBranches}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  View Branches
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ManageBranch"
                                  name="ManageBranch"
                                  checked={role.ManageBranch}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  Manage Branch
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ViewOrders"
                                  name="ViewOrders"
                                  checked={role.ViewOrders}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  View Orders
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ManageOrder"
                                  name="ManageOrder"
                                  checked={role.ManageOrder}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  Manage Order
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ViewProducts"
                                  name="ViewProducts"
                                  checked={role.ViewProducts}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  View Products
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ManageProduct"
                                  name="ManageProduct"
                                  checked={role.ManageProduct}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  Manage Product
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ViewMenuImages"
                                  name="ViewMenuImages"
                                  checked={role.ViewMenuImages}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  View Menu Images
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ManageMenuImages"
                                  name="ManageMenuImages"
                                  checked={role.ManageMenuImages}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  Manage Menu Images
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ViewMedia"
                                  name="ViewMedia"
                                  checked={role.ViewMedia}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  View Media
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <Form.Check className="mb-3 form-check">
                                <FormCheck.Input
                                  type="checkbox"
                                  className=""
                                  id="ManageMedia"
                                  name="ManageMedia"
                                  checked={role.ManageMedia}
                                  onChange={(e) => handleCheckboxesInput(e)}
                                />
                                <FormCheck.Label
                                  className=""
                                  htmlFor="exampleCheck1"
                                >
                                  Manage Media
                                </FormCheck.Label>
                              </Form.Check>
                            </Form.Group>
                          </Row>
                        </div>
                      </Row>
                      <hr className="mb-5" />

                      {/* collections */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Collections
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewCollections"
                              name="ViewCollections"
                              checked={role.ViewCollections}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Collections
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageCollections"
                              name="ManageCollections"
                              checked={role.ManageCollections}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Collections
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Menu */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Menues
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewMenus"
                              name="ViewMenus"
                              checked={role.ViewMenus}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Menus
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageMenus"
                              name="ManageMenus"
                              checked={role.ManageMenus}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Menus
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Ingredients */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Ingredients
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewIngredients"
                              name="ViewIngredients"
                              checked={role.ViewIngredients}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Ingredients
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageIngredients"
                              name="ManageIngredients"
                              checked={role.ManageIngredients}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Ingredients
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />

                      {/* Countries */}
                      <Row>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Countries
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ViewCountries"
                              name="ViewCountries"
                              checked={role.ViewCountries}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              View Countries
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <Form.Check className="mb-3 form-check">
                            <FormCheck.Input
                              type="checkbox"
                              className=""
                              id="ManageCountries"
                              name="ManageCountries"
                              checked={role.ManageCountries}
                              onChange={(e) => handleCheckboxesInput(e)}
                            />
                            <FormCheck.Label
                              className=""
                              htmlFor="exampleCheck1"
                            >
                              Manage Countries
                            </FormCheck.Label>
                          </Form.Check>
                        </Form.Group>
                      </Row>
                      <hr className="mb-5" />
                    </Col>
                  </Row>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ManageRole;
