import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";
import { geolocated } from "react-geolocated";
import Demo from "./Demo";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";

import { Row, Col, Form, Button } from "react-bootstrap";
import { options } from "preact";
import { Variables } from "../../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateLocale } from "moment";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD5uxW4zqjmJCKgI7v_JyyNEycRx76iZbs&vv=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <>
    <GoogleMap
      onClick={(ev) => {
        props.setLat(ev.latLng.lat());
        props.setLng(ev.latLng.lng());
        props.setMarker({ lat: ev.latLng.lat(), lng: ev.latLng.lng() });

        var array = props.branchDetails;
        array.Lat = ev.latLng.lat();
        array.Lng =  ev.latLng.lng();
        props.setBranchDetails(array);
        console.log("Array",array)
        console.log("x");
      }}
      defaultZoom={12}
      defaultCenter={
        props.branchDetails.Lat === 0 || props.branchDetails.Lng === 0
          ? { lat: props.lat, lng: props.lng }
          : { lat: props.branchDetails.Lat, lng: props.branchDetails.Lng }
      }
    >
      {props.branchDetails.Lat !== 0 && props.branchDetails.Lng !== 0 && (
        <Marker position={{ lat: props.branchDetails.Lat, lng: props.branchDetails.Lng }} />
      )}
    </GoogleMap>
  </>
));

function BranchLocation(props) {
  const [lat, setLat] = useState(33.871594256384945); // branch location todo
  const [lng, setLng] = useState(35.50831362537152); // branch location todo
  const [marker, setMarker] = useState(null);

  useEffect(() => {}, [marker,props.branchDetails]);
  return (
    <div>
      <MyMapComponent
        lat={lat}
        setLat={setLat}
        lng={lng}
        setLng={setLng}
        marker={marker}
        setMarker={setMarker}
        branchDetails={props.branchDetails}
        setBranchDetails={props.setBranchDetails}
      />
      <Demo />
    </div>
  );
}

export default BranchLocation;
