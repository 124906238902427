import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";

function arePointsNear(checkPoint, centerPoint, km) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
}

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD5uxW4zqjmJCKgI7v_JyyNEycRx76iZbs&vv=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <>
    <GoogleMap
      onClick={(ev) => {
        var array = [...props.att];

        array.push({ lat: ev.latLng.lat(), lng: ev.latLng.lng() });
        props.setAtt(array);

        // var x = arePointsNear({ lat: 33.871594256384945, lng: 35.50831362537152 },{ lat: ev.latLng.lat, lng: ev.latLng.lng })
        var x = arePointsNear(
          { lat: ev.latLng.lat(), lng: ev.latLng.lng() },
          { lat: 33.871594256384945, lng: 35.50831362537152 },
          1
        );
        console.log("props.custom", props.custom);
      }}
      defaultZoom={12}
      defaultCenter={props.att[0]}
      onGoogleApiLoaded={({ map, maps }) =>
        new maps.Circle({
          strokeColor: "#FFFFFF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFFFFF",
          fillOpacity: 0.3,
          map,
          center: { lat: "33.871594256384945", lng: "35.50831362537152" },
          radius: 275,
        })
      }
    >
      {props.att.map((data, i) => (
        <Circle
          key={i}
          strokeColor="#a84432"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#a84432"
          fillOpacity={0.35}
          center={props.att[i]}
          radius={props.custom}
          options={{ fillColor: "#EE80AE33", strokeColor: "#EE80AE66" }}
          draggable={true}
          onDragEnd={(event) => console.log(event.latLng.toString())}
          onDrag={console.log("mm")}
          onClick={(x) => {
            var array = [...props.att];
            array.splice(i, 1);
            props.setAtt(array);
          }}
        />
      ))}

      {/* <Circle
    strokeColor="#FF0000"
    strokeOpacity={0.35}
    strokeWeight={2}
    fillColor="#FF0000"
    fillOpacity={0.35}
    center={{ lat: 33.871594256384945, lng: 35.50831362537152 }}
    radius={1000}
    onClick={(x) => {
      var array = [...props.att];
      array.splice(0, 1);
      props.setAtt(array);
    }}
  /> */}
    </GoogleMap>
    <h1>sjsj</h1>
  </>
));

function Test2(props) {
  useEffect(() => {}, [props.att]);
  return (
    <div>
      <MyMapComponent
        custom={props.custom}
        att={props.att}
        setAtt={props.setAtt}
      />
    </div>
  );
}

export default Test2;
