import React, { useEffect, useState } from 'react'
import { Button, Row } from 'react-bootstrap';
import { Variables } from '../../../Variables1/Variables';

import BranchDeliveryMap from './BranchDeliveryMap'

function BranchDelivery(props) {

    
    const [customRadius,setCustomRadius] = useState(1000);

    // Get Branch Locations
  const GetBranchLocs = async () => {
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/GetBranchDeliveryLocation/"+props.branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
        console.log("fetch",result)
      props.setMapAttributes(result);
    }
  };

    useEffect(() => {
        GetBranchLocs()
      }, []);

      console.log("att",props.mapAttributes)

  return (
    <Row>
        <BranchDeliveryMap branchDetails={props.branchDetails} GetBranchLocs={GetBranchLocs} mapAttributes={props.mapAttributes} setMapAttributes={props.setMapAttributes} customRadius={customRadius} setCustomRadius={setCustomRadius} branchId={props.branchId}/>
        
    </Row>
  )
}

export default BranchDelivery