import React, { useState, useEffect } from "react";
import { useNavigate,Link } from "react-router-dom";
import { Variables } from "../../Variables1/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import CustomSnipper from "../../components/customSnipper";
import {
    Row,
    Col,
    Form,
    Button,
    Spinner,
    Tab,
    Tabs,
    Nav,
    FormCheck,
    Card
  } from "react-bootstrap";
  import {BiEditAlt } from "react-icons/bi";

function RolesList() {

    const navigate = useNavigate();

    const [loader,setLoader] = useState(false);
    const [roles,setRoles] = useState([]);

    // Get Roles
    const GetRoles = async () => {
        setLoader(true);
        let response = await fetch(
          Variables.API_URL + "UserRole/GetUserRolesForDdl",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          setRoles(result);
        }
        setLoader(false);
      };

      useEffect(() => {
          GetRoles();
      },[])

      console.log(roles)

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Roles List</h4>
              </div>
                <Button
                  variant="primary"
                  onClick={() => navigate("/Role/0")}
                >
                  Add New Role
                </Button>
            </Card.Header>
            <Card.Body className="px-0">
              {loader === true ? (
                <CustomSnipper />
              ) : (
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th min-width="100px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((tdata, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            {tdata.Name}
                          </td>
                          <td className="text-center">
                              <div className="flex align-items-center list-user-action">
                                <Link
                                  className="btn btn-sm btn-icon btn-success"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"                                  
                                  to={"/Role/"+tdata.RoleId}
                                >
                                  <BiEditAlt />
                                </Link>{" "}
                              </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default RolesList