import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../Variables1/Variables";
import axios from "axios";
import Util from "../../Classes/Util";
import { Row, Col, Button, Card, Tab, Nav, Spinner } from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import OwnerInfoDetails from "./Components/OwnerInfoDetails";
import RestaurantInfoDetails from "./Components/RestaurantInfoDetails";
import RestaurantProductsList from "./Components/RestaurantProductsList";
import BranchesList from "./Components/BranchesList";
import MenuImages from "./Components/MenuImages";
import RestaurantMedia from "./Components/RestaurantMenu";
import Orders from "./Components/Orders";

function ManageRestaurant() {
  const navigate = useNavigate();
  const [RestoName, setRestoName] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("RestaurantDetails");

  //#region //////////////////// Restaurant Details

  const [loader, setLoader] = useState(false);
  const RouteRestaurantId = useParams();
  const [restaurantId, setRestaurantId] = useState(
    Number(RouteRestaurantId.RouteRestaurantId) || 0
  );

  const [ownerInfo, setOwnerInfo] = useState({
    FirstName: "",
    LastName: "",
    GenderId: 0,
    Email: "",
    PhoneNumber: "",
  });
  const current = new Date();
  const [restaurantDetails, setRestaurantDetails] = useState({
    RestaurantName: "",
    AverageCost: "",
    DueDate: "",
    About: "",
    IsDeliveryActive: true,
    DeliverySuspensionDueDate: `${current.getFullYear()}/${current.getMonth()+1}/${current.getDate()}`,
    HasDeliveryStatusChangePermission: false,
    MinimumOrderPrice: []
  });
  const [logo, setLogo] = useState("");
  const [logoToDb, setLogoToDb] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [mainIamgePath, setMainImagePath] = useState("");
  const [logoPath, setLogoPath] = useState("");
  const [mainImageToDb, setMainImageToDb] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [userId, setUserId] = useState(0);

  // Get Branch Details
  const GetRestaurantDetails = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "Restaurant/GetRestaurantDetailsForAdmin/" +
        restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setOwnerInfo(result.OwnerInfo);
      setRestaurantDetails(result.RestaurantDetails);
      setSelectedCountries(result.Countries);
      setLogoPath(result.Logo);
      setMainImagePath(result.MainImage);
      setRestoName(result.RestaurantDetails.RestaurantName);
    } else {
      //setBranchIdExist(false);
    }
    setLoader(false);
  };

  // Check input validity
  function IsValid() {
    var hasError = false;
    if (ownerInfo.FirstName === "" || ownerInfo.FirstName === null) {
      var element = document.getElementById("FirstName");
      element.classList.add("required");
      hasError = true;
    }
    if (ownerInfo.LastName === "" || ownerInfo.LastName === null) {
      var element = document.getElementById("LastName");
      element.classList.add("required");
      hasError = true;
    }
    if (
      ownerInfo.GenderId === 0 ||
      ownerInfo.GenderId === "0" ||
      ownerInfo.GenderId === null
    ) {
      var element = document.getElementById("GenderId");
      element.classList.add("required");
      hasError = true;
    }
    if (
      ownerInfo.Email === "" ||
      ownerInfo.Email === null ||
      validator.isEmail(ownerInfo.Email) === false
    ) {
      var element = document.getElementById("Email");
      element.classList.add("required");
      hasError = true;
    }
    if (ownerInfo.PhoneNumber === "" || ownerInfo.PhoneNumber === null) {
      var element = document.getElementById("PhoneNumber");
      element.classList.add("required");
      hasError = true;
    }
    if (
      restaurantDetails.RestaurantName === "" ||
      restaurantDetails.RestaurantName === null
    ) {
      var element = document.getElementById("RestaurantName");
      element.classList.add("required");
      hasError = true;
    }
    if (
      restaurantDetails.AverageCost === "" ||
      restaurantDetails.AverageCost === null
    ) {
      var element = document.getElementById("AverageCost");
      element.classList.add("required");
      hasError = true;
    }
    if (
      restaurantDetails.DueDate === "" ||
      restaurantDetails.DueDate === null
    ) {
      var element = document.getElementById("DueDate");
      element.classList.add("required");
      hasError = true;
    }
    if (restaurantDetails.About === "" || restaurantDetails.About === null) {
      var element = document.getElementById("About");
      element.classList.add("required");
      hasError = true;
    }
    if (selectedCountries.length == 0) {
      var element = document.getElementById("Countries").childNodes[0];
      element.classList.add("required");
      hasError = true;
    }

    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // Handle Insert
  const HandleInsert = async (e) => {
    setButtonLoader(true);
    e.preventDefault();

    let response = await fetch(
      Variables.API_URL + "Restaurant/AddNewRestaurant",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //body: formData,
        body: JSON.stringify({
          AdminId: userId,
          OwnerInfo: ownerInfo,
          RestaurantDetails: restaurantDetails,
          Countries: selectedCountries,
          MenuSections: selectedMenuSections,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      // insert logo and main image

      const formData = new FormData();
      formData.append("Image", logoToDb);
      formData.append("RestaurantId", result);
      formData.append("FolderPath", "Logo");

      const resLogo = await axios.post(
        Variables.API_URL + "Restaurant/UploadRestoMedia",
        formData
      );
      console.log("Path", resLogo.data);
      if (resLogo.data !== "") {
        let responsePath = await fetch(
          Variables.API_URL + "Restaurant/InsertRestaurantImageToDb",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Path: resLogo.data,
              ImageType: "Logo",
              RestaurantId: result,
            }),
          }
        );
        let resultInsertImagePath = await responsePath.json();
      }

      const formData2 = new FormData();
      formData2.append("Image", mainImageToDb);
      formData2.append("RestaurantId", result);
      formData2.append("FolderPath", "Logo");

      const resMainImage = await axios.post(
        Variables.API_URL + "Restaurant/UploadRestoMedia",
        formData2
      );
      if (resMainImage.data !== "") {
        let responsePathMainImage = await fetch(
          Variables.API_URL + "Restaurant/InsertRestaurantImageToDb",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Path: resMainImage.data,
              ImageType: "MainImage",
              RestaurantId: result,
            }),
          }
        );
        let resultInsertImagePathMain = await responsePathMainImage.json();
      }

      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => setRestaurantId(result), 1000);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setButtonLoader(false);
  };

  // Handle Insert
  const HandleUpdate = async () => {
    setButtonLoader(true);
    console.log("update ", restaurantDetails);
    let response = await fetch(
      Variables.API_URL + "Restaurant/AdminUpdateRestaurant",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantId: restaurantId,
          AdminId: 12,
          RestaurantDetails: restaurantDetails,
          Countries: selectedCountries,
          MenuSections: selectedMenuSections,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      // insert logo and main image

      if (logo !== "") {
        console.log("Logoo");
        console.log("logoToDb", logoToDb);
        console.log("restaurantId", restaurantId);
        console.log("FolderPath", "Logo");
        const formData = new FormData();
        formData.append("Image", logoToDb);
        formData.append("RestaurantId", restaurantId);
        formData.append("FolderPath", "Logo");

        const resLogo = await axios.post(
          Variables.API_URL + "Restaurant/UploadRestoMedia",
          formData
        );
        console.log("resLogo.data", resLogo.data);

        if (resLogo.data !== "") {
          let responsePath = await fetch(
            Variables.API_URL + "Restaurant/InsertRestaurantImageToDb",
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                Path: resLogo.data,
                ImageType: "Logo",
                RestaurantId: restaurantId,
              }),
            }
          );
          let resultInsertImagePath = await responsePath.json();
        }
      }

      if (mainImage !== "") {
        console.log("main image");
        console.log("mainImageToDb", mainImageToDb);
        console.log("restaurantId", restaurantId);
        console.log("FolderPath", "MainImage");

        const formData2 = new FormData();
        formData2.append("Image", mainImageToDb);
        formData2.append("RestaurantId", restaurantId);
        formData2.append("FolderPath", "MainImage");

        const resMainImage = await axios.post(
          Variables.API_URL + "Restaurant/UploadRestoMedia",
          formData2
        );
        console.log("resMainImage.data", resMainImage.data);
        if (resMainImage.data !== "") {
          let responsePathMainImage = await fetch(
            Variables.API_URL + "Restaurant/InsertRestaurantImageToDb",
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                Path: resMainImage.data,
                ImageType: "MainImage",
                RestaurantId: restaurantId,
              }),
            }
          );
          let resultInsertImagePathMain = await responsePathMainImage.json();
        }
      }

      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setButtonLoader(false);
  };

  // Handle Sumbit
  const HandleSumbit = async (e) => {
    var isValid = IsValid();
    if (isValid) {
      // case of insert
      if (restaurantId === 0) {
        HandleInsert(e);
      }
      // case of update
      else {
        HandleUpdate(e);
        // setTimeout(() => setDetailsLoader(false), 200);
      }
    } else {
      toast.error("Please Fill All Required Fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //#endregion

  //#region ////////////////////// Branches

  const [branches, setBranches] = useState([]);
  const [activeModelShow, setActiveModelShow] = useState(0);
  const [branchListLoader, setBranchListLoader] = useState(false);

  // Get Branches List
  const GetBranchesList = async () => {
    setBranchListLoader(true);
    let response = await fetch(
      Variables.API_URL + "RestoBranch/GetRestaurantBranches/" + restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setBranches(result);
    }
    setBranchListLoader(false);
  };

  // Active Deactivate Branch
  const ActiveDeactivateBranch = async (branch) => {
    setBranchListLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "RestoBranch/ActiveDeactiveBranch/Restaurant/" +
        restaurantId +
        "/Branch/" +
        branch,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      GetBranchesList();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
    setActiveModelShow(0);
    setBranchListLoader(false);
  };

  //#endregion

  //#region ////////////////////// Media

  const [GeneralMedia, setGeneralMedia] = useState([]);
  const [generalMediaLoader, setGeneralMediaLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [imageModelView, setImageModelView] = useState(0);
  const [deleteImageModel, setDeleteImageModel] = useState(0);

  // Get General Media
  const GetRestaurantGeneralMedia = async () => {
    setGeneralMediaLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "Restaurant/GetRestaurantGeneralMedia/" +
        restaurantId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setGeneralMedia(result);
    }
    setGeneralMediaLoader(false);
  };

  const DeleteImage = async (imageId) => {
    let response = await fetch(
      Variables.API_URL + "Restaurant/DeleteRestaurantGeneralMedia/" + imageId,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      GetRestaurantGeneralMedia();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //#endregion

  //#region ///////////////////// Menu Images

  const [menuImages, setMenuImages] = useState([]);
  //#endregion

  //#region ///////////////////// Menu Sections

  const [menuSections, setMenuSections] = useState([]);
  const [selectedMenuSections, setSelectedMenuSections] = useState([]);
  //#endregion

  useEffect(() => {
    if (restaurantId !== 0) {
      if ("UserId" in localStorage) {
        setUserId(Util.decrypt(localStorage.getItem("UserId")));
      }

      GetRestaurantDetails();

      GetBranchesList();

      GetRestaurantGeneralMedia();
    }
  }, [restaurantId, RestoName, activeTab]);

  console.log(restaurantDetails)

  return (
    <div>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Restaurant - {RestoName}</h4>
              </div>
            </Card.Header>
            {loader === true ? <CustomSnipper /> : ""}
            <Card.Body>
              <div className="new-user-info">
                <form>
                  <div className="row">
                    <div className="bd-example">
                      <Tab.Container defaultActiveKey={activeTab}>
                        <Nav>
                          <Nav
                            variant="tabs"
                            className="mb-3"
                            id="nav-tab"
                            role="tablist"
                          >
                            <Nav.Link
                              eventKey="RestaurantDetails"
                              variant=" d-flex align-items-center"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              Restaurant Details
                            </Nav.Link>
                            {restaurantId !== 0 ? (
                              <>
                                <Nav.Link
                                  eventKey="Branches"
                                  variant=""
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  Branches
                                </Nav.Link>
                                <Nav.Link
                                  eventKey="Orders"
                                  variant=""
                                  id="nav-orders-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-orders"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-orders"
                                  aria-selected="false"
                                >
                                  Orders
                                </Nav.Link>
                                <Nav.Link
                                  eventKey="Products"
                                  variant=""
                                  id="nav-profile-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-profile"
                                  aria-selected="false"
                                >
                                  Products
                                </Nav.Link>

                                <Nav.Link
                                  eventKey="MenuImages"
                                  variant=""
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  Menu Images
                                </Nav.Link>
                                <Nav.Link
                                  eventKey="Media"
                                  variant=""
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  Media
                                </Nav.Link>
                              </>
                            ) : (
                              ""
                            )}
                          </Nav>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane
                            className=" fade show"
                            eventKey="RestaurantDetails"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <br></br>

                            {/* Owner information section */}
                            <OwnerInfoDetails
                              ownerInfo={ownerInfo}
                              setOwnerInfo={setOwnerInfo}
                              restaurantId={restaurantId}
                              loader={loader}
                              setActiveTab={setActiveTab}
                            />

                            <br />

                            <br />

                            {/* Restaurant Info Details */}
                            <RestaurantInfoDetails
                              selectedCountries={selectedCountries}
                              setSelectedCountries={setSelectedCountries}
                              logo={logo}
                              setLogo={setLogo}
                              mainImage={mainImage}
                              setMainImage={setMainImage}
                              restaurantDetails={restaurantDetails}
                              setRestaurantDetails={setRestaurantDetails}
                              setLogoToDb={setLogoToDb}
                              setMainImageToDb={setMainImageToDb}
                              restaurantId={restaurantId}
                              loader={loader}
                              logoPath={logoPath}
                              mainIamgePath={mainIamgePath}
                              menuSections={menuSections}
                              setMenuSections={setMenuSections}
                              selectedMenuSections={selectedMenuSections}
                              setSelectedMenuSections={setSelectedMenuSections}
                              setActiveTab={setActiveTab}
                            />

                            <Row>
                              <Col>
                                <Button
                                  type="button"
                                  variant="btn btn-primary"
                                  className="float-right"
                                  onClick={(e) => HandleSumbit(e)}
                                >
                                  {buttonLoader === true ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </>
                                  ) : userId === 0 ? (
                                    "Add New User"
                                  ) : (
                                    "Update Restaurant"
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane
                            className=" fade"
                            id="nav-Products"
                            eventKey="Products"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <RestaurantProductsList
                              restaurantId={restaurantId}
                              setActiveTab={setActiveTab}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            className=" fade"
                            id="nav-contact"
                            eventKey="Branches"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                          >
                            <br></br>

                            {/* Branches List */}
                            <BranchesList
                              restaurantId={restaurantId}
                              setActiveTab={setActiveTab}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            className=" fade"
                            id="nav-orders"
                            eventKey="Orders"
                            role="tabpanel"
                            aria-labelledby="nav-orders-tab"
                          >
                            <br></br>

                            <Orders restaurantId={restaurantId}/>
                          </Tab.Pane>

                          <Tab.Pane
                            className=" fade"
                            id="nav-contact"
                            eventKey="MenuImages"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                          >
                            <br></br>

                            <MenuImages
                              menuImages={menuImages}
                              setMenuImages={setMenuImages}
                              restaurantId={restaurantId}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            className=" fade"
                            id="nav-contact"
                            eventKey="Media"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                          >
                            <RestaurantMedia restaurantId={restaurantId} />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ManageRestaurant;
